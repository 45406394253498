<template>
  <div class="page-container environment-container">
    <div class="environment-content">
      <p class="environment-title">集训环境</p>
      <div class="environment-img-box">
        <ul>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_4.png"
            />
          </li>
          <li class="img-small">
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_5.png"
            />
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_2.png"
            />
          </li>
          <li class="img-small">
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_6.png"
            />
          </li>
          <li class="img-small">
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_7.png"
            />
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_1.jpg"
            />
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="fill"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/environment/enviorment_3.png"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.environment-container{
  .environment-content{
    padding-top: 20px;
    .environment-title{
      font-size: 36px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }
    .environment-img-box{
      margin-top: 50px;
      overflow-x: auto;
      >ul{
        height: 300px;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        justify-items: flex-start;
        overflow-y: hidden;
        width: 700px;
        >li{
          width: 250px;
          height: 150px;
          overflow: hidden;
          /deep/.van-image__img{
            transform: scale(1.1);
          }
          &.img-small{
            width: 100px;
          }
        }
      }
    } 
  }
}
</style>