import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { getBannerImgList } from "@/api/common"

export default new Vuex.Store({
  state: {
    // 是否显示头部
    isShowHeader: true,
    // 是否显示尾部
    isShowFooter: true,
    // 是否登录了
    isShowLogin: false,
    // 用户学习
    userInfo: {},
  },
  getters: {
    getIsShowHeader(state) {
      return state.isShowHeader;
    },
    getIsShowFooter(state) {
      return state.isShowFooter;
    },
    getUserInfo(state) {
			return state.userInfo;
		}
  },
  mutations: {
    setIsShowHeader(state, newval) {
      state.isShowHeader = newval;
    },
    setIsShowFooter(state, newval) {
      state.isShowFooter = newval;
    },
    setUserInfo: (state, data) => {
			state.userInfo = data;
		},
		setisShowLogin: (state, bool) => {
			state.isShowLogin = bool;
		},
  },
  actions: {
    getBannerImgList({commit}, params) {
			return new Promise((resolve, reject) => {
				getBannerImgList(params).then(res => {
					if (res.code === 200) {
						resolve(res);
					} else {
						reject();
					}
				})
			})
		}
  },
  modules: {
  }
})
