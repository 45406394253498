var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courses-container"},[_c('van-swipe',{staticClass:"page-banner",attrs:{"autoplay":3000,"indicator-color":"#35edff"}},_vm._l((_vm.bannerList),function(item){return _c('van-swipe-item',{key:item.id},[_c('van-image',{attrs:{"width":"100%","height":"100%","fit":"cover","lazy-load":"","src":item.imgUrl},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner","size":"20"}})]},proxy:true}],null,true)})],1)}),1),_c('div',{staticClass:"page-screen-box",class:_vm.isScreenBar && 'page-screen-bar'},[_c('div',{ref:"forumScreen",staticClass:"page-screen page-screen-flex"},[_c('div',{staticClass:"screen-left"},[_c('div',{staticClass:"van-tabs van-tabs--line"},[_c('div',{staticClass:"van-tabs__wrap van-tabs__wrap--scrollable"},[_c('div',{staticClass:"van-tabs__nav van-tabs__nav--line van-tabs__nav--complete",staticStyle:{"border-color":"rgb(53, 237, 255)","background":"transparent"},attrs:{"role":"tablist"}},[_c('div',{staticClass:"van-tab",class:!_vm.queryParams.classify && 'van-tab--active',on:{"click":function($event){return _vm.eventChangeClassify()}}},[_c('span',{staticClass:"van-tab__text"},[_vm._v("首页")])]),_c('div',{staticClass:"van-tab",class:_vm.queryParams.classify &&
                  _vm.queryParams.classify !== 4 &&
                  'van-tab--active'},[_c('van-dropdown-menu',{attrs:{"active-color":"#35edff"}},[_c('van-dropdown-item',{attrs:{"title":_vm.getClassifyTitle(_vm.queryParams.classify),"options":_vm.typeOption},on:{"change":_vm.eventChangeClassify},model:{value:(_vm.queryParams.classify),callback:function ($$v) {_vm.$set(_vm.queryParams, "classify", $$v)},expression:"queryParams.classify"}})],1)],1),_c('div',{staticClass:"van-tab",class:_vm.queryParams.classify &&
                  _vm.queryParams.classify === 4 &&
                  'van-tab--active',on:{"click":function($event){return _vm.eventChangeClassify(4)}}},[_c('span',{staticClass:"van-tab__text"},[_vm._v("综合性课程")])])])])])]),_c('div',{staticClass:"screen-right"},[_c('van-field',{attrs:{"right-icon":"search","placeholder":"搜索课程名称","clearable":""},on:{"click-right-icon":_vm.eventSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.eventSearch.apply(null, arguments)}},model:{value:(_vm.queryParams.seriesName),callback:function ($$v) {_vm.$set(_vm.queryParams, "seriesName", $$v)},expression:"queryParams.seriesName"}})],1)])]),_c('div',{staticClass:"courses-list",style:({
      marginTop: _vm.isScreenBar ? '170px' : '0',
      minHeight: (_vm.isScreenBar || _vm.isEnableSearch) && 'calc(100vh - 45px)',
    })},[(!_vm.dataList.length && !_vm.refreshLoading && !_vm.loadLoading)?_c('van-empty',{attrs:{"description":"空空如也~"}}):_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshLoading),callback:function ($$v) {_vm.refreshLoading=$$v},expression:"refreshLoading"}},[_c('van-list',{ref:"vanlist",attrs:{"finished":_vm.finished,"finished-text":"没有更多了哟~","offset":0},on:{"load":_vm.onListLoad},model:{value:(_vm.loadLoading),callback:function ($$v) {_vm.loadLoading=$$v},expression:"loadLoading"}},[_c('van-row',{attrs:{"gutter":"10","type":"flex","justify":"space-between"}},_vm._l((_vm.dataList),function(item,index){return _c('van-col',{key:index,attrs:{"span":"12"}},[_c('router-link',{attrs:{"tag":"a","target":"_blank","to":`/courses/detail?id=${item.id}`}},[_c('item',{attrs:{"content":item}})],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }