<template>
  <div class="item-container">
    <div class="item-cover">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        lazy-load
        :src="content.cover"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <div class="item-star-box" v-if="Number(content.recommendFlag) === 1">
        <van-icon name="star" color="#fbd25d" class="item-star" />
      </div>
    </div>
    <div class="item-user">
      <div class="item-user-header-img">
        <van-image lazy-load :src="content.headImg" width="100%" height="100%">
          <template v-slot:error><van-icon name="user-circle-o" /></template>
        </van-image>
      </div>
      <p class="text-over-ellipsis">{{ content.nickName || "未知" }}</p>
    </div>
    <div class="item-content">
      <p class="item-content-title text-over-ellipsis">{{ content.title }}</p>
      <div class="item-content-type">
        <van-image
          v-if="getValueByList(classifyImgList, 'id', content.classfyId)"
          width="18px"
          height="18px"
          lazy-load
          :src="getValueByList(classifyImgList, 'id', content.classfyId).src"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="14" />
          </template>
        </van-image>
        <!-- <img src="@/assets/img/unity.png" v-if="content.classfyId === 64"/>
        <img src="@/assets/img/ue.png" v-if="content.classfyId === 65"/>
        <img src="@/assets/img/ae.png" v-if="content.classfyId === 66"/> -->
        <span>{{ content.className }}</span>
      </div>
    </div>
    <div class="item-live">
      <div class="item-live-el">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/37.png"
        />
        <p>{{ content.browseNumber }}</p>
      </div>
      <div class="item-live-el">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/38.png"
        />
        <p>{{ content.commentNum }}</p>
      </div>
      <div class="item-live-el">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/39.png"
        />
        <p>{{ content.collectNum }}</p>
      </div>
      <div class="item-live-el">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/40.png"
        />
        <p>{{ content.likeNum }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classifyImgList: [
        {
          id: 64,
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/unity.png",
        },
        {
          id: 65,
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/ue.png",
        },
        {
          id: 66,
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/ae.png",
        },
      ],
    };
  },
  props: {
    content: {
      type: Object,
      require: true,
    },
  },
  methods: {
    // 根据属性值查找到数组中对应的对象元素
    // list contrastAttribute 对比属性 、contrastVal 对比值
    getValueByList(list, contrastAttribute, contrastVal) {
      return this.classifyImgList.find(
        (el) => el[contrastAttribute] === contrastVal
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 100%;
  color: #ffffff;
  background-color: #0b1525;
  text-align: left;
  margin-top: 10px;
  .item-cover {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 88%;
    > img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    /deep/.van-image {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #3d3e51;
    }

    .item-star-box {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 0;
      height: 0;
      border-top: 20px solid #0b1525;
      border-right: 20px solid #0b1525;
      border-left: 20px solid transparent;
      border-bottom: 20px solid transparent;
      .item-star {
        position: relative;
        top: -15px;
        right: -1px;
        font-size: 14px;
      }
    }
  }
  .item-user {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0 5%;
    .item-user-header-img {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > p {
      margin-left: 5px;
      width: calc(100% - 23px);
      font-size: 12px;
      text-align: left;
      zoom: 0.9;
    }
  }

  .item-content {
    padding: 0 5% 5px;
    width: 100%;
    .item-content-title {
      width: 100%;
      font-size: 14px;
      margin-top: 5px;
      color: #00e1e1;
    }
    .item-content-type {
      font-size: 12px;
      color: #8cadb0;
      margin-top: 5px;
      zoom: 0.8;
      display: flex;
      align-items: center;
      > img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .item-live {
    width: 100%;
    padding: 5px 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        #000e29 3%,
        #4b4f70 35%,
        #4b4f70 65%,
        #000e29 97%
      );
    }
    .item-live-el {
      color: rgba(132, 142, 144, 0.8);
      font-size: 12px;
      zoom: 0.6;
      display: flex;
      align-items: center;
      > img {
        width: 20px;
        height: 16px;
      }
    }
  }
}
</style>