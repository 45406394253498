var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container home-container"},[_c('div',{staticClass:"home-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"home-content-type"},[_c('div',{staticClass:"content-type-item"},[_c('div',{staticClass:"item-top",on:{"click":function($event){return _vm.eventToPage(4, {curriculumActive: 1})}}},[_vm._m(2),_c('div',{staticClass:"item-name"},[_vm._v("Unity引擎特效课程")])]),_vm._m(3)]),_c('div',{staticClass:"content-type-item"},[_c('div',{staticClass:"item-top",on:{"click":function($event){return _vm.eventToPage(4, {curriculumActive: 2})}}},[_vm._m(4),_c('div',{staticClass:"item-name"},[_vm._v("3dsMax游戏动作课程")])]),_vm._m(5)])]),_c('div',{staticClass:"home-content-footer",on:{"click":function($event){return _vm.eventToPage(10)}}},[_c('p',[_vm._v("留言咨询")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-content-title"},[_c('p',[_vm._v("怪力屋集训营")]),_c('p',[_vm._v("游戏美术精品课程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-content-describe"},[_c('div',{staticClass:"home-describe-icon"},[_vm._v("精品")]),_c('p',[_vm._v("怪力屋 • 游戏美术")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-icon"},[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/Unity.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-flag"},[_c('p',[_vm._v("基础课堂")]),_c('p',[_vm._v("进阶课堂")]),_c('p',[_vm._v("6个月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-icon"},[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/3dsmax.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-flag"},[_c('p',[_vm._v("基础课堂")]),_c('p',[_vm._v("进阶课堂")]),_c('p',[_vm._v("6个月")])])
}]

export { render, staticRenderFns }