<template>
  <div class="item-container">
    <div class="item-cover">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        lazy-load
        :src="content.cover"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <div class="item-icon-box" v-if="content.renew">更新</div>
    </div>
    <div class="item-title text-over-ellipsis">{{ content.seriesName }}</div>
    <div class="item-duty">
      {{ content.introduction }}
    </div>
    <div class="item-release">
      <div class="item-release-user">
        <van-image
          width="20px"
          height="14px"
          lazy-load
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/favicon.png"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
        <p>{{ content.teacher }}</p>
      </div>
    </div>
    <div class="item-time">发布时间：{{ content.createTime }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    content: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 100%;
  color: #ffffff;
  background-color: #0b1525;
  text-align: left;
  margin-top: 10px;
  padding-bottom: 10px;

  .item-cover {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 75%;
    /deep/.van-image {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #3d3e51;
    }

    .item-icon-box {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 50px;
      height: 20px;
      background-color: #d20d0d;
      text-align: center;
      line-height: 20px;
      letter-spacing: 2px;
    }
  }

  .item-title {
    color: #ffffff;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
    padding: 0 5%;
  }

  .item-duty {
    color: #016985;
    font-size: 12px;
    padding: 0 5%;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .item-release {
    margin-top: 5px;
    padding: 0 5%;
    .item-release-user {
      display: flex;
      align-items: center;
      > p {
        font-size: 12px;
        margin-left: 2px;
      }
    }
  }

  .item-time {
    margin-top: 5px;
    padding: 0 5%;
    font-size: 12px;
  }
}
</style>