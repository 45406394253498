import instance from "./request.js";

// 查询系列列表
export function getCoursesList(data){
  return instance.get("/api/video/list",{params:data});
}

// 查看系列详情
export function getSeriesDetailById(data){
  return instance.get("/api/video/query",{params:data});
}

// 根据观看时长获取积分
export function setIntegralByTime(data) {
  return instance.get(`/api/video/watch/${data.vid}/${data.type}`);
}