<template>
  <div class="page-container series-container">
    <div class="series-content">
      <div class="series-box">
        <div class="series-item">
          <div class="item-top">
            <div class="item-top-text">
              <p>Unity引擎</p>
              <p>特效课程</p>
            </div>
            <div class="item-top-img">
              <img v-lazy="'https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/Unity.png'" />
            </div>
          </div>
          <div class="item-content-text">
            在学习过程中将经历大量项目实战；和怪力技术人员面对面，让你快速掌握Unity游戏特效核心技术，并具备相应的工作能力。
          </div>
          <div class="item-content-btn">
            <span @click="eventToPage(4, { curriculumActive: 1 })"
              >查看详情<van-icon name="down" class="item-icon"
            /></span>
          </div>
        </div>
        <div class="series-item">
          <div class="item-top">
            <div class="item-top-text">
              <p>3dsMax</p>
              <p>动作课程</p>
            </div>
            <div class="item-top-img">
              <img v-lazy="'https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/3dsmax.png'" />
            </div>
          </div>
          <div class="item-content-text">
            从关键Pose到连击，通过代表性案例让学员快速掌握游戏动作要点，提高项目实战能力，完成小白到动画师的蜕变。
          </div>
          <div class="item-content-btn">
            <span @click="eventToPage(4, { curriculumActive: 2 })"
              >查看详情<van-icon name="down" class="item-icon"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    eventToPage(index, attributesObj) {
      this.$emit("eventToPage", index, attributesObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.series-container {
  .series-content {
    margin-top: 20px;
    .series-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .series-item {
        display: flex;
        flex-direction: column;
        width: 80%;
        // height: 340px;
        padding: 40px 20px 10px 20px;
        box-sizing: border-box;
        border-width: 5px;
        border-style: solid;
        border-image-source: linear-gradient(to top, #6f9ee3, #c07d99);
        border-image-slice: 1;
        color: #9abfcc;
        background-color: rgba(40, 45, 60, 0.9);
        &:nth-child(2) {
          margin-top: 20px;
          border-image: linear-gradient(to top, #6f9ee3, #9778d6) 1;
        }
        .item-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;
          .item-top-text {
            font-size: 20px;
            font-weight: bold;
            text-align: left;
          }
          .item-top-img {
            width: 60px;
            height: 60px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .item-content-text {
          margin-top: 30px;
          height: 50px;
          font-size: 12px;
          letter-spacing: 2px;
          text-align: left;
          zoom: 0.9;
        }
        .item-content-btn {
          font-size: 12px;
          margin-top: 30px;
          color: #35edff;
          font-weight: bold;
          text-align: left;
          span {
            cursor: pointer;
            .item-icon {
              transform: rotate(-90deg);
              margin-left: 2px;
            }
          }
        }
      }
    }
  }
}
</style>