<template>
  <div id="app">
    <header-menu></header-menu>
    <router-view />
    <footer-box></footer-box>
  </div>
</template>

<script>
import headerMenu from "@/layout/components/header.vue";
import footerBox from "./layout/components/footer.vue";

export default {
  name: "App",
  components: {
    headerMenu,
    footerBox,
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #202237;
  min-height: 100vh;
}
</style>
