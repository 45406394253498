import instance from "./request.js";

// 首页banner图列表
export function getBannerImgList(data){
  return instance.get("/api/banner/list",{params: data});
}

// 查询作品列表
export function getWorkList(data){
  return instance.get("/api/work/list",{params:data});
}

// 查询作品详情
export function getWorksDetail(id){
  return instance.get(`/api/work/query/${id}`);
}