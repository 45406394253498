<template>
  <div class="footer-container" v-if="isShowFooter">
    <div class="footer-text-content">
      <a href="https://beian.miit.gov.cn/" target="view_window"
        >粤ICP备2021083385号</a
      >
      <p>Copyright©2021 广州怪力视效网络科技有限公司 版权所有</p>
    </div>
    <div class="footer-follow">
      <p>关注我们</p>
      <div class="footer-follow-box">
        <div class="footer-follow-item">
          <span class="iconfont icon-weixin"></span>
        </div>
        <div class="footer-follow-item">
          <span class="iconfont icon-QQ"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isShowFooter: 'getIsShowFooter',
    })
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  width: 100%;
  background-color: #0f1128;
  padding: 20px 15px;
  color: $default-color;
  text-align: center;
  .footer-text-content {
    line-height: 24px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -50px;
      width: 100px;
      height: 2px;
      background-color: $default-color;
    }
    > a {
      color: $default-color;
      font-size: 12px;
    }
    >p{
      font-size: 12px;
      zoom: 0.8;
    }
  }
  .footer-follow {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    >p{
      font-size: 12px;
      zoom: 0.8;
    }
    .footer-follow-box {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .footer-follow-item {
        width: 30px;
        height: 30px;
        border: 1px solid $default-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        >.iconfont{
          font-size: 16px;
        }
        &:not(:first-child){
          margin-left: 10px;
        }
      }
    }
  }
}
</style>