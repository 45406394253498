<template>
  <div class="page-container curriculum-container">
    <div class="curriculum-content">
      <div class="curriculum-tabs">
        <!-- <div class="curriculum-tabs-item"><p class="item-square"></p></div> -->
        <div
          v-for="item in curriculumList"
          :key="item.index"
          class="curriculum-tabs-item"
          :class="
            curriculumActive === item.index && 'curriculum-tabs-item-active'
          "
          v-html="item.title"
          @click="changeActive(item.index, item.typeList)"
        ></div>
      </div>
      <van-tabs type="card">
        <van-tab
          :title="item.title"
          v-for="(item, index) in acitveTypeList"
          :key="index"
        >
          <div class="curriculum-list">
            <ul>
              <li class="text-over-ellipsis" v-for="(citem, cindex) in item.contentList" :key="cindex">{{cindex + 1 + '.' + citem}}</li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curriculumActive: 1,
      // acitveTypeList: [],
      curriculumList: [
        {
          title: "Unity<br/>引擎特效课程",
          index: 1,
          typeList: [
            {
              title: "基础课程",
              contentList: [
                "PS、3dsMax软件基础",
                "Unity引擎软件基础",
                "粒子与模型在特效中的运用",
                "ASE制作常用特效Shader",
                "动画编辑与TimeLine",
                "插件与脚本的调用",
                "后期光效与滤镜的调节",
              ],
            },
            {
              title: "进阶课程",
              contentList: [
                "不同职业的普攻与重击",
                "法师技能的多种表现技法",
                "防御、治疗等Buff制作",
                "蓄力与施法",
                "元素特征与运动规律",
                "色彩搭配与动态节奏",
                "画面构图与颜色层次",
                "项目规范与职业操守",
              ],
            },
          ],
        },
        {
          title: "3dsMax<br/>游戏动作课程",
          index: 2,
          typeList: [
            {
              title: "基础课程",
              contentList: [
                "3dsMax软件基础",
                "动画原理基础",
                "蒙皮与绑定",
                "关键Pose设计制作",
                "走跑跳等基础动作方法",
                "不同形式攻击动作分析",
              ],
            },
            {
              title: "进阶课程",
              contentList: [
                "连击动作设计",
                "表演动作设计",
                "四足动物动作制作",
                "受击、死亡等动作表现",
                "动作与情感表现",
                "项目规范与职业操守",
                "毕业作品设计与指导",
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    acitveTypeList() {
      let index = this.curriculumList.findIndex(v => v.index === this.curriculumActive);
      return this.curriculumList[index].typeList;
    }
  },
  created() {
    // this.acitveTypeList = this.curriculumList[0].typeList;
  },
  methods: {
    changeActive(index, list) {
      this.curriculumActive = index;
      // this.acitveTypeList = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.curriculum-container {
  .curriculum-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .curriculum-tabs {
      width: 90%;
      margin: 0 30px;
      display: flex;
      align-items: flex-end;
      border-bottom: 2px solid #9abfcc;
      .curriculum-tabs-item {
        text-align: left;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
        > .item-square {
          width: 84px;
          height: 36px;
          border: 1px solid #647a84;
          background-color: rgba(40, 45, 60, 0.9);
        }
        &.curriculum-tabs-item-active {
          position: relative;
          font-size: 16px;
          color: transparent;
          background-image: linear-gradient(90deg, #d73860, #6c85e5);
          -webkit-background-clip: text;
          &::after {
            content: "";
            width: calc(100% - 20px);
            height: 2px;
            background-image: linear-gradient(90deg, #d73860, #6c85e5);
            position: absolute;
            left: 10px;
            bottom: -2px;
          }
        }
      }
    }
    /deep/.van-tabs {
      margin-top: 4.5vh;
      .van-tabs__nav--card {
        border: 1px solid #9abfcc;
        background-color: transparent;
        .van-tab {
          color: #9abfcc;
          padding: 5px 10px;
          border-color: #9abfcc;
        }
        .van-tab.van-tab--active {
          color: #ffffff;
          background-color: rgba($color: #9abfcc, $alpha: 0.2);
        }
      }
    }

    .curriculum-list {
      margin-top: 3vh;
      width: 90vw;
      border: 1px solid #647a84;
      background-color: rgba(40, 45, 60, 0.9);
      padding: 10px;
      font-size: 14px;
      > ul {
        > li {
          width: 100%;
          line-height: 40px;
          text-align: left;
          text-indent: 20px;
          &:not(:first-child) {
            border-top: 1px solid rgba($color: #647a84, $alpha: 0.4);
          }
        }
      }
    }
  }
}
</style>