<template>
  <div class="forum-container">
    <!-- 轮播图 -->
    <van-swipe class="page-banner" :autoplay="3000" indicator-color="#35edff">
      <van-swipe-item v-for="item in bannerList" :key="item.id">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          lazy-load
          :src="item.imgUrl"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    <!-- 筛选栏 -->
    <div class="forum-screen-box">
      <div
        class="forum-screen"
        :class="isScreenBar && 'forum-screen-bar'"
        ref="forumScreen"
      >
        <div class="screen-left">
          <van-tabs
            v-model="queryParams.type"
            background="transparent"
            color="#35edff"
            title-inactive-color="#FFFFFF"
            title-active-color="#35edff"
            :ellipsis="false"
            @change="eventChangeTabs"
          >
            <van-tab title="最新" name="1"></van-tab>
            <van-tab title="星标" name="2"></van-tab>
          </van-tabs>
        </div>
        <div
          class="screen-right"
        >
          <van-field
            v-model="queryParams.title"
            right-icon="search"
            placeholder="搜索作品名称"
            @click="eventClickInput"
            @focus="eventClickInput"
            clearable
            @click-right-icon="eventSearch"
            @keydown.enter="eventSearch"
          />
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="forum-list" :style="{ marginTop: isScreenBar ? '70px' : '0', minHeight: (isScreenBar || isEnableSearch) && 'calc(100vh - 45px)' }">
      <van-empty
        description="空空如也~"
        v-if="!workList.length && !refreshLoading && !loadLoading"
      />
      <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh" v-else>
        <van-list
          v-model="loadLoading"
          :finished="finished"
          finished-text="没有更多了哟~"
          @load="onListLoad"
          :offset="0"
          ref="vanlist"
        >
          <van-row gutter="10" type="flex" justify="space-between">
            <van-col span="8" v-for="(item, index) in workList" :key="index">
              <router-link tag="a" :to="`/forum/detail?id=${item.id}`" target="_blank">
                <item :content="item"></item>
              </router-link>
            </van-col>
          </van-row>
          <template v-slot:finished v-if="workList.length < total">
            <van-button
              type="theme"
              style="width: 100%; margin-top: 10px"
              @click="eventLoadData"
              >加载更多</van-button
            >
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getBannerImgList, getWorkList } from "@/api/forum.js";
import { Toast } from "vant";
import item from "./components/item.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      searchText: undefined,
      refreshLoading: false,
      loadLoading: false,
      finished: false,
      bannerList: [],
      queryParams: {
        workType: 1,
        pageNum: 0,
        pageSize: 12,
        title: undefined,
        type: "1",
      },
      workList: [],
      // 总数
      total: 0,
      isScreenBar: false,
      // 最多上拉加载的数据条数
      loadMoreTotal: 48,
      // 是否唤起输入框
      isEnableSearch: false,
    };
  },
  components: {
    item,
  },
  created() {
    this.getBannerList();
    this.refreshLoading = true;
  },
  mounted() {
    // 新增滚动事件
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    ...mapMutations({
      setIsShowHeader: "setIsShowHeader",
    }),
    // 监听滚动
    handleScrollx() {
      this.isScreenBar = window.pageYOffset >= 222;
      if (window.pageYOffset < 222) this.isEnableSearch = false;
      this.setIsShowHeader(!this.isScreenBar);
    },
    // 获取banner图列表
    async getBannerList() {
      let { code, rows } = await getBannerImgList({
        type: 1,
      });
      if (code === 200) {
        this.bannerList = rows;
      }
    },
    // 获取作品列表
    async getList() {
      let { code, rows, total } = await getWorkList(this.queryParams);
      if (code === 200) {
        this.total = total;
        return rows;
      }
      return [];
    },
    // 切换tab
    eventChangeTabs() {
      if (this.isScreenBar) {
        document.documentElement.scrollTop = 222;
      }
      this.refreshLoading = true;
      this.onRefresh();
    },
    // 下拉刷新触发
    onRefresh() {
      this.finished = false;
      this.loadLoading = true;
      this.onListLoad();
    },
    // 上拉加载触发
    async onListLoad() {
      this.queryParams.pageNum += 1;
      if (this.refreshLoading) {
        this.workList = [];
        this.queryParams.pageNum = 1;
        this.refreshLoading = false;
      }
      let dataList = await this.getList();
      this.workList = this.workList.concat(dataList);
      this.loadLoading = false;
      let operationTotal =
        this.total > this.loadMoreTotal ? this.loadMoreTotal : this.total;
      this.finished =
        this.queryParams.pageNum >= operationTotal / this.queryParams.pageSize;
    },
    // 加载数据
    eventLoadData() {
      this.finished = false;
      this.$refs.vanlist.check();
    },
    // 点击搜索框
    eventClickInput() {
      document.documentElement.scrollTop = 222;
      this.handleScrollx();
      this.isEnableSearch = true;
    },
    eventSearch() {
      this.refreshLoading = true;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.forum-container {
  color: #000;
  .forum-banner {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .forum-screen-box {
    height: 45px;
    width: 100%;
  }
  .forum-screen {
    display: flex;
    background-color: $default-bg;
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #323547;
    .screen-left {
      width: 50%;

      /deep/.van-tab.van-tab--active{
        &::after{
          content: '';
          width: 40px;
          height: 3px;
          position: absolute;
          bottom: 0;
          background-color: $default-color;
        }
      }
      /deep/.van-tabs__line{
        display: none;
      }
    }
    .screen-right {
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      /deep/.van-cell {
        padding: 5px 15px;
        height: 36px;
        border: 1px solid #323232;
        background-color: #3d3e51;
        caret-color: #ffffff;
        color: #ffffff;
      }

      /deep/.van-field__control{
        color: #FFFFFF;
      }
    }

    &.forum-screen-bar {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
    }
  }

  .forum-list {
    padding: 5px 10px 15px;
    min-height: calc(100vh - 350px);
  }
}
</style>