<template>
  <div class="page-container job-container">
    <div class="job-content">
      <div class="job-title">就业推荐</div>
      <div class="job-row">
        <div class="job-row-item">
          <p>广州怪力视效</p>
          <p>实习机会</p>
        </div>
        <div class="job-row-item">
          <p>网易、腾讯、字节等大厂内推就业名额</p>
          <p>各大厂内推</p>
        </div>
        <div class="job-row-item">
          <p>全国范围内推荐就业</p>
          <p>推荐就业</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.job-container{
  .job-content{
    padding-top: 20px;
    .job-title{
      font-size: 36px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }

    .job-row{
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100vh - 150px);
      max-height: 300px;
      padding: 0 30px;
      .job-row-item{
        width: 100%;
        color: #9abfcc;
        font-size: 12px;
        text-align: left;
        >p:first-child{
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>