<template>
  <div class="page-container consulting-container">
    <div class="consulting-content">
      <div class="consulting-title">报名方式</div>
      <div class="consulting-box">
        <div class="consulting-item">
          <p>1.咨询QQ：2032686515</p>
          <p>2.服务电话：19120680543</p>
          <p>3.报名地址：<br />广州市黄埔区光谱中路11号云升科学园D栋403</p>
        </div>
        <div class="consulting-item">
          <p>
            <span style="font-size: 18px;">怪力屋不一样</span><br />
            网易、腾讯、字节等名师亲临指导<br/>
            百余款一线大厂优秀项目经验<br/>
            近百名高级特效师、200余人专业特效团队<br/>
            学技术、练项目、卷高薪<br/>
            更多大厂技术，等你来学
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.consulting-container {
  .consulting-content {
    padding-top: 20px;
    .consulting-title {
      font-size: 36px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }
    .consulting-box {
      margin: 50px 5vw 0;
      width: 90vw;
      .consulting-item {
        width: 100%;
        border: 1px solid #647a84;
        background-color: rgba(40, 45, 60, 0.9);
        padding: 10px;
        font-size: 14px;
        text-align: left;
        color: #809faa;
        > p {
          line-height: 24px;
          &:not(:first-child) {
            margin-top: 15px;
          }
        }
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>