<template>
  <div class="courses-container">
    <van-swipe class="page-banner" :autoplay="3000" indicator-color="#35edff">
      <van-swipe-item v-for="item in bannerList" :key="item.id">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          lazy-load
          :src="item.imgUrl"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    <!-- 筛选栏 -->
    <div class="page-screen-box" :class="isScreenBar && 'page-screen-bar'">
      <div
        class="page-screen page-screen-flex"
        ref="forumScreen"
      >
        <div class="screen-left">
          <div class="van-tabs van-tabs--line">
            <div class="van-tabs__wrap van-tabs__wrap--scrollable">
              <div
                role="tablist"
                class="van-tabs__nav van-tabs__nav--line van-tabs__nav--complete"
                style="border-color: rgb(53, 237, 255); background: transparent"
              >
                <div
                  class="van-tab"
                  :class="!queryParams.classify && 'van-tab--active'"
                  @click="eventChangeClassify()"
                >
                  <span class="van-tab__text">首页</span>
                </div>
                <div
                  class="van-tab"
                  :class="
                    queryParams.classify &&
                    queryParams.classify !== 4 &&
                    'van-tab--active'
                  "
                >
                  <van-dropdown-menu active-color="#35edff">
                    <van-dropdown-item
                      :title="getClassifyTitle(queryParams.classify)"
                      v-model="queryParams.classify"
                      :options="typeOption"
                      @change="eventChangeClassify"
                    />
                  </van-dropdown-menu>
                </div>
                <div
                  class="van-tab"
                  :class="
                    queryParams.classify &&
                    queryParams.classify === 4 &&
                    'van-tab--active'
                  "
                  @click="eventChangeClassify(4)"
                >
                  <span class="van-tab__text">综合性课程</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="screen-right"
        >
          <van-field
            v-model="queryParams.seriesName"
            right-icon="search"
            placeholder="搜索课程名称"
            clearable
            @click-right-icon="eventSearch"
            @keydown.enter="eventSearch"
          />
          <!-- <van-button
            v-else
            icon="search"
            style="
              height: 40px;
              background-color: #0b1525;
              border: none;
              color: #ffffff;
            "
            @click="eventChangeScroll"
          /> -->
        </div>
      </div>
    </div>
    <div
      class="courses-list"
      :style="{
        marginTop: isScreenBar ? '170px' : '0',
        minHeight: (isScreenBar || isEnableSearch) && 'calc(100vh - 45px)',
      }"
    >
      <van-empty
        description="空空如也~"
        v-if="!dataList.length && !refreshLoading && !loadLoading"
      />
      <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh" v-else>
        <van-list
          v-model="loadLoading"
          :finished="finished"
          finished-text="没有更多了哟~"
          @load="onListLoad"
          :offset="0"
          ref="vanlist"
        >
          <van-row gutter="10" type="flex" justify="space-between">
            <van-col span="12" v-for="(item, index) in dataList" :key="index">
              <router-link
                tag="a"
                target="_blank"
                :to="`/courses/detail?id=${item.id}`"
              >
                <item :content="item"></item>
              </router-link>
            </van-col>
          </van-row>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getCoursesList } from "@/api/courses";
import item from "./components/item.vue";
import mixin_screen from "@/mixin/screen";
import { judgeDeviceType } from "@/utils/index"
import { Toast } from 'vant';
export default {
  data() {
    return {
      bannerList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 4,
        classify: undefined,
        seriesName: undefined,
      },
      searchType: 1,
      dataList: [],
      refreshLoading: false,
      loadLoading: false,
      finished: false,
      typeOption: [
        { text: "特效基础入门", value: 1 },
        { text: "特效基础进阶", value: 2 },
        { text: "特效基础高级", value: 3 },
      ],
    };
  },
  mixins: [mixin_screen],
  components: {
    item,
  },
  created() {
    this.getBanner();
    this.refreshLoading = true;
  },
  methods: {
    // 获取banner图列表
    ...mapActions(["getBannerImgList"]),
    async getBanner() {
      this.getBannerImgList({ type: 5 }).then((res) => {
        if (res.code === 200) {
          this.bannerList = res.rows || [];
        }
      });
    },
    // 修改tab
    eventChangeTabs() {},
    // 获取作品列表
    async getList() {
      let { code, rows, total } = await getCoursesList(this.queryParams);
      if (code === 200) {
        this.total = total;
        return rows;
      }
      return [];
    },
    // 下拉刷新触发
    onRefresh() {
      this.finished = false;
      this.loadLoading = true;
      this.onListLoad();
    },
    // 上拉加载触发
    async onListLoad() {
      this.queryParams.pageNum += 1;
      if (this.refreshLoading) {
        this.dataList = [];
        this.queryParams.pageNum = 1;
        this.refreshLoading = false;
      }
      let dataList = await this.getList();
      this.dataList = this.dataList.concat(dataList);
      this.loadLoading = false;
      let operationTotal =
        this.total > this.loadMoreTotal ? this.loadMoreTotal : this.total;
      this.finished =
        this.queryParams.pageNum >= operationTotal / this.queryParams.pageSize;
    },
    // 加载数据
    eventLoadData() {
      this.finished = false;
      this.$refs.vanlist.check();
    },
    eventSearch() {
      this.refreshLoading = true;
      this.onRefresh();
    },
    eventClickInput() {},
    // 点击特效基础
    eventChangeClassify(value) {
      this.queryParams.classify = value;
      this.refreshLoading = true;
      this.onRefresh();
    },
    // 获取标题字段
    getClassifyTitle() {
      if (!this.queryParams.classify || this.queryParams.classify === 4) {
        return "特效基础";
      }
      let title = this.typeOption.find(
        (v) => v.value === this.queryParams.classify
      ).text;
      return title || "特效基础";
    },
    //  点击搜索按钮
    eventChangeScroll() {
      document.documentElement.scrollTop = 222;
      this.isScreenBar = true;
      this.setIsShowHeader(!this.isScreenBar);
    },
  },
};
</script>

<style lang="scss" scoped>
.courses-container {
  .screen-left {
    width: 100%;

    .van-tab {
      color: #ffffff;
    }
    .van-tab.van-tab--active {
      color: rgb(53, 237, 255);
    }

    /deep/ .van-dropdown-menu__bar {
      background-color: transparent;
      box-shadow: none;
    }

    /deep/.van-dropdown-item {
      .van-popup,
      .van-cell {
        background-color: #202237;
        color: #ffffff;
      }
      .van-cell {
        &::after {
          border-color: #323547;
        }
      }
    }

    /deep/.van-dropdown-menu__title {
      color: #ffffff;
    }
  }

  .screen-right {
    width: 100%;
    padding: 10px;
  }

  .courses-list {
    padding: 5px 10px 15px;
    min-height: calc(100vh - 350px);
  }

  .page-screen-flex {
    flex-direction: column-reverse;
  }
}
</style>