<template>
  <div class="detail-container">
    <div class="detail-top-box">
      <!-- <video
        x5-video-player-type="h5"
        webkit-playsinline="true" 
        playsinline
        x5-playsinline
        controlslist="nodownload noremoteplayback noplaybackrate"
        autoplay="autoplay"
        controls="controls"
        disablePictureInPicture
        disableRemotePlayback
        class="detail-video-box"
        v-if="activeWorkObj && activeWorkObj.type === 'video'"
        :key="activeWorkObj.src"
      >
        <source :src="activeWorkObj && activeWorkObj.src" />
        您的浏览器不支持 video 标签。
      </video> -->
      <div
        class="detail-video-box"
        v-if="activeWorkObj && activeWorkObj.type === 'video'"
      >
        <video-player
          class="vjs-custom-skin"
          ref="videoPlayer"
          :events="events"
          :options="playerOptions"
          :playsinline="true"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @canplay="playerCanplay($event)"
          @ready="playerReadied"
          @isFullscreen="handlefullscreenchange($event)"
        >
        </video-player>
      </div>

      <van-image
        fit="contain"
        lazy-load
        :src="activeWorkObj.src"
        v-if="activeWorkObj && activeWorkObj.type === 'img'"
        width="100%"
        height="100%"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <div class="detail-work-list">
      <div class="detail-work-imgs">
        <div
          class="detail-work-item"
          :class="item.cover === activeWorkObj.cover && 'detail-work-item-active'"
          v-for="(item, index) in workVideoImgList"
          :key="item.cover"
          @click="eventChangeActive(index)"
        >
          <img :src="item.cover" />
        </div>
      </div>
    </div>

    <div class="detail-work-info">
      <p class="work-title">{{ workDetailInfo.title }}</p>
      <p class="work-time">
        发布时间：{{
          workDetailInfo.createTime | dateformat("YYYY/MM/DD HH:mm")
        }}
      </p>
    </div>
    <!-- 图片预览 -->
    <van-image-preview
      v-model="isShowPreview"
      :images="images"
      @change="onChange"
    >
    </van-image-preview>
  </div>
</template>

<script>
import Vue from "vue";
import VueVideoPlayer from "vue-video-player";
Vue.use(VueVideoPlayer);
window.videojs = VueVideoPlayer.videojs;
import "video.js/dist/video-js.css";
require("video.js/dist/lang/zh-CN.js");

import { getWorksDetail } from "@/api/forum.js";
import { mapMutations } from "vuex";
import "video.js/dist/video-js.css";
import { Toast } from "vant";
export default {
  data() {
    return {
      events: ["isFullscreen"],
      isShowPreview: false,
      workDetailInfo: {},
      workVideoImgList: [],
      activeWorkObj: {},
      images: [],
      skillText: undefined,
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    playerOptions() {
      return {
        autoplay: true,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.activeWorkObj.src,
          },
        ],
        poster: this.activeWorkObj.cover,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: false,
          remainingTimeDisplay: true, // 剩余时间
          fullscreenToggle: true, // 全屏按钮
          currentTimeDisplay: true, // 当前时间
          volumeControl: false, // 声音控制键
          playToggle: true, // 暂停和播放键
          progressControl: true, // 进度条
        },
      };
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getWorkDetail(this.$route.query.id);
    }
    this.setIsShowFooter(false);
  },
  mounted() {
    document.addEventListener("fullscreenchange", () => {
      const isFullScreen =
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      if (isFullScreen) {
        //  进入全屏
        console.log("进入全屏");
        Toast("进入全屏");
      } else {
        //  退出全屏
        console.log("退出全屏");
        Toast({ message: "退出全屏", position: "bottom" });
      }
    });
  },
  methods: {
    ...mapMutations({
      setIsShowFooter: "setIsShowFooter",
    }),
    // 修改预览图片的index
    onChange() {},
    // 获取作品详情
    async getWorkDetail(id) {
      let { code, data } = await getWorksDetail(id);
      if (code === 200) {
        this.workDetailInfo = data;
        if (
          this.workDetailInfo.classifyList &&
          this.workDetailInfo.classifyList[0].id === 41
        ) {
          this.skillText = this.escapeStringHTML(
            JSON.parse(this.workDetailInfo.description)
          );
        } else {
          this.handleVideoImg(data.videoUrl, data.files);
        }
      }
    },
    // 处理视频和图片的方法
    handleVideoImg(videoList, imgList) {
      this.workVideoImgList = [];
      videoList &&
        videoList.forEach((item) => {
          this.workVideoImgList.push({
            type: "video",
            src: item,
            cover: this.getVideoCover(item),
          });
        });
      imgList &&
        imgList.forEach((item) => {
          this.workVideoImgList.push({
            type: "img",
            src: item.url,
            cover: item.url,
          });
        });
      this.activeWorkObj = this.workVideoImgList[0];
    },
    // 把实体格式字符串转义成HTML格式的字符串
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    // 截取视频封面
    getVideoCover(fileSrc) {
      let lastIndex_start = fileSrc.lastIndexOf("/");
      let name = fileSrc.substring(lastIndex_start + 1);
      let file_prefix = fileSrc.substring(
        0,
        fileSrc.lastIndexOf("/", fileSrc.lastIndexOf("/") - 1) + 1
      );
      return file_prefix + "images/" + name + "_0.jpg";
    },
    // 切换选中
    eventChangeActive(index) {
      this.activeWorkObj = this.workVideoImgList[index];
    },

    onPlayerPlay(player) {
      //this.$emit("play");
    },
    onPlayerPause(player) {
      player.pause();
    },
    playerReadied(player) {
      console.log("val：", player);
    },
    playerCanplay(player) {
      console.log("val：", player);
    },
    currentVideoPause() {
      this.$refs.videoPlayer.player.pause();
    },
    currentVideoPlay() {
      this.$refs.videoPlayer.player.play();
    },
    // 监听全屏事件
    handlefullscreenchange(player) {
      Toast("提示内容");
      console.log("val：", player);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  .detail-top-box {
    width: 100%;
    min-height: 200px;

    .detail-video-box {
      // width: 100%;
      // height: 100%;

      // position: fixed;
      // top: 0;
      // left: 0;
      // bottom: 0;
      // right: 0;
      // background: #000;
    }

    .video-player {
      // position: fixed;
      // top: 0;
      // bottom: 0;
      // z-index: 10;
      // width: 100vh;
      // height: 100vw;
      // transform-origin: 0 50%;
      // transform: rotate(-90deg) translate(-50%, 50%);
    }

    /deep/.video-js .vjs-big-play-button {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .vjs-icon-placeholder:before {
        font-size: 60px;
        width: auto;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    /deep/.video-js .vjs-poster {
      background-size: 100% 100%;
    }

    /deep/.video-js .vjs-volume-panel {
      display: none;
    }
    /deep/.video-js.vjs-fluid {
      height: 100%;
      .vjs-tech {
        object-fit: cover;
      }
    }

    // /deep/.vjs-custom-skin > .video-js {
    //   pointer-events: none
    // }

    // //音量按钮
    // video::-webkit-media-controls-mute-button {
    //   display: none;
    // }
    // video::-webkit-media-controls-toggle-closed-captions-button {
    //   display: none;
    // }
    // //音量的控制条
    // video::-webkit-media-controls-volume-slider {
    //   display: none;
    // }
  }

  .detail-work-list {
    width: 100%;
    overflow: hidden;
    .detail-work-imgs {
      display: flex;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      .detail-work-item {
        //width: 45%;
        // height: 0;
        // padding-top: 30%;
        position: relative;
        &:not(:first-child) {
          margin-left: 5px;
        }
        > img {
          //object-fit: cover;
          //position: absolute;
          // top: 0;
          // left: 0;
          width: 150px;
          height: 100px;
          // height: 100%;
          // z-index: 0;
        }
      }
      .detail-work-item-active {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border: 1px solid #00dfff;
          box-sizing: border-box;
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .detail-work-info {
    padding: 20px 15px;
    .work-title {
      color: #ffffff;
      font-size: 20px;
      text-align: left;
    }
    .work-time {
      margin-top: 10px;
      color: #b1b1b1;
      text-align: left;
    }
  }
}
</style>