import Vue from 'vue';
import { NavBar, Button, Tab, Tabs, Icon, Swipe, SwipeItem, Lazyload, Field, PullRefresh, List, Toast, Col, Row, Image as VanImage, Loading, Popup, Sidebar, SidebarItem, Empty, ImagePreview, Grid, GridItem, Pagination, Tag, DropdownMenu, DropdownItem, Form  } from "vant";
Vue.use(NavBar);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Field);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Toast);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Popup);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Empty);
Vue.use(ImagePreview);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Pagination);
Vue.use(Tag);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);