import axios from "axios";
import { Toast } from "vant";
import store from "@/store/index.js";
import router from "@/router/index.js";
import Cookie from 'js-cookie';

// console.log(process.env, process.env.VUE_APP_BASE_URL);

// 根据开发环境获取对应接口
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredent: false,
});

// 添加请求拦截器
instance.interceptors.request.use(async function (config) {
  // 在发送请求之前做些什么
  // 设置请求头，告诉服务端不要缓存，获取最新数据
  config.headers['Access-Control-Allow-Origin'] = "*";
  if (Cookie.get('accessToken')) {
    config.headers['Authorization'] = 'Bearer ' + Cookie.get('accessToken') || '';
  }
  if (Cookie.get('userInfo') && Cookie.get('userInfo') != 'undefined') {
    store.commit('setUserInfo', JSON.parse(Cookie.get('userInfo')))
    store.commit('setisShowLogin', true);
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 关闭加载
  // store.commit("changeIsPending",false)
  // 对响应数据做点什么
  // console.log('response',response);
  if (response.data.code && response.data.code == 401) {
    Toast({
      message: '请登录你的账号',
      position: 'top',
      icon: 'close'
    })
    Cookie.remove('userInfo');
    Cookie.remove('accessToken');
    setTimeout(() => {
      store.commit('setisShowLogin', false);
      router.push('/login')
    }, 1000);
    return false;
  } else if (response.data.code && response.data.code == 500) {
    Toast.fail(response.data.msg);
    return false;
  } else if (response.data.code && response.data.code == 256) {
    //store.commit('setShowLoginDialog', true);
    return false;
  } else if (response.data.code && response.data.code != 200) {
    if (!store.state.loginModel) {
      Toast.fail(response.data.msg);
    }
    // return  false;
  }
  return response.data;

}, function (error) {
  return Promise.reject(error);
});


export default instance