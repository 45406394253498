<template>
  <div class="login-container">
    <div class="login-box">
      <van-tabs
        v-model="active"
        color="#00c0ff"
        background="transparent"
        title-active-color="#FFFFFF"
        title-inactive-color="#959eb8"
      >
        <van-tab title="验证码登录"></van-tab>
        <van-tab title="密码登录"></van-tab>
      </van-tabs>
      <div class="login-form-box">
        <van-form @submit="onSubmit">
          <van-field
            v-model="dataForm.account"
            name="手机号"
            label="手机号"
            colon
            label-width="60px"
            placeholder="请输入手机号码"
          />
          <van-field
            v-model="dataForm.verifyCode"
            center
            clearable
            placeholder="请输入短信验证码"
            style="margin-top: 20px"
            maxlength="6"
            v-if="active === 0"
          >
            <template #button>
              <van-button
                size="small"
                color="#00c0ff"
                @click="eventGetCodes"
                :disabled="!downBool"
                >{{ codeBtnText }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="dataForm.password"
            type="password"
            name="密码"
            label="密码"
            colon
            placeholder="请输入密码"
            label-width="60px"
            style="margin-top: 20px"
            v-if="active === 1"
          />
          <div style="margin-top: 20px">
            <van-button
              block
              type="info"
              :loading="loginLoading"
              native-type="submit"
              color="#00c0ff"
              >登录</van-button
            >
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getPhoneAuthCode, codeLogin, loginByPwd } from "@/api/user.js";
export default {
  data() {
    return {
      dataForm: {
        account: undefined,
        password: undefined,
        verifyCode: undefined,
      },
      active: 0,
      downBool: true,
      interval: null,
      codeTime: 60,
      codeBtnText: "获取验证码",
      loginLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loginLoading = true;
      try {
        if (!this.dataForm.account) {
          Toast.fail("请输入手机号");
          this.loginLoading = false;
          return;
        }
        let reg = /^1[3-9]\d{9}$/;
        var result = reg.test(this.dataForm.account);
        if (!result) {
          Toast.fail("手机号格式错误");
          this.loginLoading = false;
          return;
        }
        if (this.active === 0) {
          if (!this.dataForm.verifyCode) {
            Toast.fail("请输入验证码");
            this.loginLoading = false;
            return;
          }
        } else if (this.active === 1) {
          if (!this.dataForm.password) {
            Toast.fail("请输入密码");
            this.loginLoading = false;
            return;
          }
        }
        Toast.loading({
          message: "登录验证中",
          forbidClick: true,
        });
        let res;
        if (this.active === 0) {
          res = await codeLogin({
            account: this.dataForm.account,
            verifyCode: this.dataForm.verifyCode,
          }).catch((e) => {
            Toast.fail(e);
            this.loginLoading = false;
            return;
          });
        } else if (this.active === 1) {
          res = await loginByPwd({
            account: this.dataForm.account,
            password: this.dataForm.password,
          }).catch((e) => {
            Toast.fail(e);
            this.loginLoading = false;
            return;
          });
        }
        Toast.clear();
        if (res.code === 200) {
          if (res.data.message) {
            Toast.success("首次登录赠送100经验值!");
          }
          if (res.data.userInfo) {
            if (
              (Number(res.data.userInfo.roleType) === 4 ||
                Number(res.data.userInfo.roleType) === 3) &&
              !("permission" in res.data.userInfo)
            ) {
              this.$set(
                res.data.userInfo,
                "permission",
                res.data.userInfo.epibolyInfo.permission
              );
            }
            this.$cookie.set("accessToken", res.data.token, {
              expires: 30,
            });
            this.$cookie.set("userInfo", JSON.stringify(res.data.userInfo), {
              expires: 30,
            });
            this.$store.commit(
              "setUserInfo",
              res.data.userInfo
            );
            this.$store.commit("setisShowLogin", true);
            Toast.success("登录成功");
            setTimeout(() => {
              this.$router.push("/forum");
              window.location.reload();
            }, 2000);
            // if (Number(res.data.userInfo.roleType) === 4) {
            //   setTimeout(() => {
            //     this.$router.push("/ectocyst");
            //     window.location.reload();
            //   }, 2000);
            // } else {
            //   setTimeout(() => {
            //     this.$router.push("/home/newest?activeIndex=0&latest=0");
            //     window.location.reload();
            //   }, 2000);
            // }
          }
        } else {
          this.loginLoading = false;
        }
      } catch (e) {
        Toast.fail(e);
        this.downBool = true;
      }
    },
    // 获取验证码
    async eventGetCodes() {
      this.downBool = false;
      try {
        if (!this.dataForm.account) {
          Toast.fail("请输入手机号");
          this.downBool = true;
          return;
        }
        let reg = /^1[3-9]\d{9}$/;
        var result = reg.test(this.dataForm.account);
        if (!result) {
          Toast.fail("手机号格式错误");
          this.downBool = true;
          return;
        }
        let { code, msg } = await getPhoneAuthCode({
          account: this.dataForm.account,
          type: 1,
        }).catch((e) => {
          Toast.fail(e);
          this.downBool = true;
          return;
        });
        if (code === 200) {
          Toast.success(msg);
          this.intervalDwon();
        } else {
          this.downBool = true;
        }
      } catch (e) {
        Toast.fail(e);
        this.downBool = true;
      }
    },
    // 验证码倒计时
    intervalDwon() {
      this.interval = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        this.codeBtnText = this.codeTime + "s重新发送";
        if (this.codeTime == 0) {
          clearInterval(this.interval);
          this.codeTime = 60;
          this.codeBtnText = "获取验证码";
          this.downBool = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100vw;
  height: calc(100vh - 72px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-bg.png");
  position: relative;
  .login-box {
    background-color: #37425d;
    width: 90%;
    min-height: 200px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    /deep/.van-tabs__nav {
      //background-color: transparent;
      border-bottom: 1px solid #3f4b69;
    }

    // /deep/.van-tab{
    //   color: #959eb8
    // }

    // /deep/.van-tab--active{
    //   color: #FFFFFF;
    // }
    .login-form-box {
      padding: 20px;

      /deep/.van-field__label {
        color: #ffffff;
      }

      /deep/.van-cell {
        background-color: #242a3a;
      }

      /deep/.van-cell::after {
        width: 0;
        height: 0;
        border: none;
      }

      /deep/input.van-field__control::-webkit-input-placeholder {
        color: #667087;
      }

      /deep/.van-field__control {
        color: #ffffff;
      }

      /deep/.van-field__button {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>