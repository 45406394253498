<template>
  <div class="header-container" v-if="isShowHeader">
    <div class="header-nav">
      <img
        src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/logo.png"
        class="header-logo"
        @click="eventToForum"
      />
      <van-icon
        :name="isShowMenu ? 'cross' : 'wap-nav'"
        color="#FFFFFF"
        @click="eventShowMenu"
      />
    </div>
    <van-popup v-model="isShowMenu" position="top">
      <div class="header-user">
        <p
          class="header-user-login"
          v-if="
            !($cookie.get('userInfo') && $cookie.get('userInfo') != 'undefined')
          "
        >
          你还未登录，点击<span @click="eventToLogin">登录</span>
        </p>
        <p class="header-user-info" v-else>
          <van-image
            width="40"
            height="40"
            round
            :src="JSON.parse($cookie.get('userInfo')).headImg"
          />
          <span>{{ JSON.parse($cookie.get("userInfo")).nickName }}</span>
        </p>
      </div>
      <van-sidebar v-model="activeKey" @change="eventMenuChange">
        <van-sidebar-item title="论坛" :to="`/forum`" />
        <!-- <van-sidebar-item title="教育培训" :to="`/train`" /> -->
        <van-sidebar-item title="在线课程" :to="`/courses`" />
      </van-sidebar>
      <div
        class="header-user-loginout"
        v-if="
          ($cookie.get('userInfo') && $cookie.get('userInfo') != 'undefined')
        "
      >
        <van-button type="primary" block color="#00c0ff" @click="eventLoginout"
          >退出登录</van-button
        >
      </div>
    </van-popup>
    <!-- <van-tabs
      v-model="menuActive"
      background="transparent"
      color="#35edff"
      title-inactive-color="#FFFFFF"
      title-active-color="#35edff"
      :ellipsis="false"
      line-height="4px"
    >
      <van-tab title="论坛" name="forum"></van-tab>
      <van-tab title="教育培训" name="train"></van-tab>
    </van-tabs> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { loginUp } from "@/api/user.js";
export default {
  data() {
    return {
      menuActive: "forum",
      isShowMenu: false,
      // activeKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      isShowHeader: "getIsShowHeader",
    }),
    activeKey: {
      get() {
        let activeKey = 0;
        // if (this.$route.path.indexOf('/train') !== -1 ) {
        //   activeKey = 1;
        // }
        if (this.$route.path.indexOf("/courses") !== -1) {
          activeKey = 1;
        }
        return activeKey;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    eventShowMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    // 菜单切换
    eventMenuChange() {
      this.eventShowMenu();
    },
    // 点击图标返回论坛界面
    eventToForum() {
      this.$router.push("/");
    },
    // 前去登录
    eventToLogin() {
      this.isShowMenu = false;
      this.$router.push("/login");
    },
    // 退出登录
    async eventLoginout() {
      let data = {
        token: this.$cookie.get("accessToken"),
      };
      let { code } = await loginUp(data);
      if (code == 200) {
        this.$cookie.remove("accessToken");
        this.$cookie.remove("userInfo");
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  height: 72px;
  .header-nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $default-bg;
    padding: 5px 15px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    .header-logo {
      width: 146px;
      height: 40px;
      margin-right: 10px;
    }

    /deep/.van-icon {
      font-size: 32px;
    }

    /deep/.van-tab__text {
      font-size: 20px;
      font-weight: bold;
    }
  }

  /deep/.van-overlay {
    z-index: 7 !important;
  }

  /deep/.van-popup {
    z-index: 8 !important;
    background-color: $default-bg;
    border-top: 1px solid #323547;

    .header-user {
      width: 100%;
      height: 60px;
      .header-user-login {
        color: #d8cfcf;
        line-height: 60px;
        font-size: 14px;
        > span {
          color: $default-color;
        }
      }
      .header-user-info {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        > span {
          margin-left: 10px;
          color: #ffffff;
          font-size: 16px;
        }
      }
    }

    .header-user-loginout {
      width: 100%;
      height: 60px;
      padding: 10px;
    }
    .van-sidebar {
      width: 100%;
      background-color: $default-bg;
      .van-sidebar-item {
        background-color: $default-bg;
        color: #ffffff;
        font-size: 18px;
        padding: 21px 0;
        &.van-sidebar-item--select {
          color: $default-color;
          &::before {
            height: 100%;
            background-color: $default-color;
          }
        }
      }
    }
  }

  /deep/.van-popup.van-popup--top {
    top: 72px !important;
  }
}
</style>