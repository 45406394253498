import instance from "./request.js";

// 获取手机验证码
export function getPhoneAuthCode(data){
  return instance.post("/api/user/getCode",data);
}

// 密码登录
export function loginByPwd(data) {
  return instance.post("/api/user/loginByPwd",data);
}

// 手机号验证码登录
export function codeLogin(data){
  return instance.post("/api/user/loginByCode",data);
}

// 微信登录
export function wechatLogin(data){
  return instance.get("/api/user/wxlogin",{params:data});
}

// 退出登录  /api/user/logOut
export function loginUp(data){
  return instance.post("/api/user/logOut",data);
}