<template>
  <div class="page-container interpolation-container">
    <div class="interpolation-content">
      <div class="interpolation-title">就业机会ㆍ大厂内推</div>
      <div class="interpolation-box">
        <ul>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y5.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y3.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
        </ul>
        <ul>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y8.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y4.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y2.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
        </ul>
        <ul>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y6.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y1.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
        </ul>
        <ul>
          <li class="circular-box">
            <span v-for="item in 6" :key="item">●</span>
          </li>
          <li>
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              lazy-load
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/interpolation/y7.jpg"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </li>
          <li class="circular-box">
            <span v-for="item in 6" :key="item">●</span>
          </li>
        </ul>
        <ul>
          <li class="more-company">更多企业</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.interpolation-container {
  .interpolation-content {
    padding-top: 20px;
    .interpolation-title {
      font-size: 28px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }
    .interpolation-box {
      margin-top: 50px;
      > ul {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        li {
          width: 25vw;
          height: 10vw;
          overflow: hidden;
          margin-left: 5vw;
          &:nth-child(1) {
            margin-left: 0;
          }
        }
        .circular-box {
          display: flex;
          align-items: center;
          width: 25vw;
          justify-content: space-between;
          color: transparent;
          background-image: linear-gradient(to right, #c86f8f, #9dbac8);
          -webkit-background-clip: text;
          font-size: 14px;
        }
        .more-company {
          font-size: 14px;
          color: #9abfcc;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}
</style>