import { mapMutations } from "vuex";
export default{
  data() {
    return {
      isScreenBar: false,
      isEnableSearch: false
    }
  },
  mounted() {
    // 新增滚动事件
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    ...mapMutations({
      setIsShowHeader: "setIsShowHeader",
    }),
    // 监听滚动
    handleScrollx() {
      this.isScreenBar = window.pageYOffset >= 222;
      if (window.pageYOffset < 222) this.isEnableSearch = false;
      this.setIsShowHeader(!this.isScreenBar);
    },
  }
}