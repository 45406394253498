<template>
  <div class="train-container">
    <div class="train-header-container">
      <div class="header-nav">
        <router-link tag="a" :to="`/`">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/logo.png"
            class="header-logo"
          />
        </router-link>
        <van-icon name="wap-nav" color="#FFFFFF" @click="eventShowPagination" />
      </div>
    </div>
    <div
      class="van-overlay"
      @click="showPagination = false"
      v-show="showPagination"
      style="z-index: 2001"
    ></div>
    <div
      v-show="showPagination"
      class="swiper-popup-pagination van-popup van-popup--right"
      id="custom"
      style="z-index: 2002"
    >
      <i
        role="button"
        tabindex="0"
        class="van-icon van-icon-cross van-popup__close-icon van-popup__close-icon--top-right"
        @click="eventShowPagination"
      ></i>
      <div id="pagination-list" class="pagination-list"></div>
      <!-- <div class="pagination-list pagination-bottom">
        <span class="swiper-pagination-custom-item">课程案例</span>
        <span class="swiper-pagination-custom-item">学员作品</span>
      </div> -->
    </div>

    <swiper class="swiper-container-par" :options="swiperOption" id="swiper">
      <!-- 首页 -->
      <swiper-slide class="swiper-slide" pageIndex="0" pageTitle="首页">
        <home @eventToPage="toSwiperPage"></home>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="0" pageTitle="首页">
        <home-case></home-case>
      </swiper-slide>
      <!-- 课程系列 -->
      <swiper-slide class="swiper-slide" pageIndex="1" pageTitle="课程系列">
        <series @eventToPage="toSwiperPage"></series>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="1" pageTitle="课程系列">
        <series-curriculum ref="curriculumEl"></series-curriculum>
      </swiper-slide>
      <!-- 课程优势 -->
      <swiper-slide class="swiper-slide" pageIndex="2" pageTitle="课程优势">
        <advantage></advantage>
      </swiper-slide>
      <!-- 讲师团队 -->
      <swiper-slide class="swiper-slide" pageIndex="3" pageTitle="讲师团队">
        <teacher></teacher>
      </swiper-slide>
      <!-- 课程案例 -->
      <swiper-slide class="swiper-slide" pageIndex="4" pageTitle="课程案例">
        <course-case @changeVideo="eventChangeVideo"></course-case>
      </swiper-slide>
      <!-- 学员作品 -->
      <swiper-slide class="swiper-slide" pageIndex="5" pageTitle="学员作品">
        <student-work @changeVideo="eventChangeVideo"></student-work>
      </swiper-slide>
      <!-- 就业机会 -->
      <swiper-slide class="swiper-slide" pageIndex="6" pageTitle="就业机会">
        <job></job>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="6" pageTitle="就业机会">
        <job-interpolation></job-interpolation>
      </swiper-slide>
      <!-- 集训环境 -->
      <swiper-slide class="swiper-slide" pageIndex="7" pageTitle="集训环境">
        <environment></environment>
      </swiper-slide>
      <!-- 课程咨询 -->
      <swiper-slide class="swiper-slide" pageIndex="8" pageTitle="课程咨询">
        <consulting></consulting>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="8" pageTitle="课程咨询">
        <question></question>
      </swiper-slide>

      <!-- 分页器 -->
      <!-- 隐藏原分页器,不可去掉 -->
      <div
        class="swiper-pagination"
        slot="pagination"
        style="display: none"
      ></div>
    </swiper>

    <!-- 视频播放 -->
    <van-popup
      :style="{ width: '90%' }"
      closeable
      v-model="showContent"
      :close-on-click-overlay="false"
    >
      <p class="video-title">{{ activeVideoObj.title }}</p>
      <video-player
        class="video-play-box vjs-custom-skin"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
      >
      </video-player>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import VueVideoPlayer from "vue-video-player";
Vue.use(VueVideoPlayer);
window.videojs = VueVideoPlayer.videojs;
import "video.js/dist/video-js.css";
require("video.js/dist/lang/zh-CN.js");
import home from "./components/home/index.vue";
import homeCase from "./components/home/index_case.vue";
import series from "./components/series/index.vue";
import seriesCurriculum from "./components/series/index_curriculum.vue";
import advantage from "./components/advantage/index.vue";
import teacher from "./components/teacher/index.vue";
import job from "./components/job/index.vue";
import jobInterpolation from "./components/job/index_interpolation.vue";
import environment from "./components/environment/index.vue";
import consulting from "./components/consulting/index.vue";
import question from "./components/consulting/index_question.vue";

// 课程案例
import courseCase from "./components/case/index.vue";
// 学员作品
import studentWork from "./components/work/index.vue"
export default {
  data() {
    const vm = this;
    return {
      showPagination: false,
      pageTypeIndex: 1,
      swiper: undefined,
      paginationListDom: undefined,
      activePageIndex: 0,
      showContent: false,
      activeVideoObj: {},
      swiperOption: {
        direction: "vertical",
        speed: 800,
        observer: true,
        observeParents: true,
        //mousewheel: true,
        simulateTouch: false,
        allowTouchMove: true,
        touchStartPreventDefault: false,
        preventClicksPropagation: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "custom",
          //这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
          renderCustom: function (swiper, current, total) {
            let _html = "";
            vm.swiper = swiper;
            let swiperChildDom = document
              .getElementById("swiper")
              .getElementsByClassName("swiper-wrapper")[0].childNodes;
            let slideDom = [];
            for (var j in swiperChildDom) {
              if (
                swiperChildDom[j].className &&
                swiperChildDom[j].className.indexOf("swiper-slide") !== -1
              ) {
                slideDom.push(swiperChildDom[j]);
              }
            }
            vm.activePageIndex =
              slideDom[current - 1].getAttribute("pageIndex");
            // 已经渲染过分页器的pageInedx
            let hadCurrentList = [];
            for (let i = 1; i <= total; i++) {
              let pageIndex = slideDom[i - 1].getAttribute("pageIndex");
              if (hadCurrentList.includes(pageIndex)) {
                continue;
              }
              let pageTitle = slideDom[i - 1].getAttribute("pageTitle");
              // current == i
              if (parseInt(vm.activePageIndex) === parseInt(pageIndex)) {
                _html +=
                  `<span class="swiper-pagination-custom-item swiper-pagination-custom-item-active" tabIndex="` +
                  i +
                  `" pageIndex="` +
                  pageIndex +
                  `">` +
                  pageTitle +
                  `</span>`;
              } else {
                _html +=
                  `<span class="swiper-pagination-custom-item" tabIndex="` +
                  i +
                  `" pageIndex="` +
                  pageIndex +
                  `">` +
                  pageTitle +
                  `</span>`;
              }
              hadCurrentList.push(pageIndex);
            }
            // 插入所得到的菜单
            let plist = document.getElementById("pagination-list");
            plist.innerHTML = _html;
            return _html;
          },
        },
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    playerOptions() {
      return {
        autoplay: true,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.activeVideoObj.src,
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: false,
          remainingTimeDisplay: true, // 剩余时间
          fullscreenToggle: true, // 全屏按钮
          currentTimeDisplay: true, // 当前时间
          volumeControl: false, // 声音控制键
          playToggle: true, // 暂停和播放键
          progressControl: true, // 进度条
        },
      };
    },
  },
  components: {
    home,
    homeCase,
    series,
    seriesCurriculum,
    advantage,
    teacher,
    job,
    jobInterpolation,
    environment,
    consulting,
    question,
    courseCase,
    studentWork
  },
  mounted() {
    this.paginationListDom = document.getElementById("pagination-list");
    this.paginationListDom.addEventListener("click", this.handleClick, false);
    document.addEventListener("touchmove", function (e) {}, { passive: true });
  },
  methods: {
    eventShowPagination() {
      this.showPagination = !this.showPagination;
    },
    handleClick(e) {
      // 获取目标元素，拿到目标元素上的 index 值
      const current = e.target;
      const toCount = current.attributes["tabIndex"].value || "";
      this.activePageIndex = current.attributes["pageIndex"].value || 0;
      // 跳转到指定的 swiper 页面
      if (toCount) {
        this.swiper.slideTo(toCount - 1);
        this.showPagination = false;
      }
    },
    onChange(index) {
      this.pageTypeIndex = index;
    },
    // 跳转界面
    toSwiperPage(page, attributesObj) {
      if (page) {
        this.swiper.slideTo(page - 1);
      }
      if (attributesObj) {
        Object.keys(attributesObj).map((key) => {
          this.$set(this.$refs.curriculumEl.$data, key, attributesObj[key]);
        });
      }
    },
    // 点击播放视频
    eventChangeVideo(src, title) {
      this.$set(this.activeVideoObj, "src", src);
      this.$set(this.activeVideoObj, "title", title);
      this.showContent = true;
    },
  },
  destroyed() {
    this.paginationListDom.removeEventListener(
      "click",
      this.handleClick,
      false
    );
  },
};
</script>

<style lang="scss" scoped>
.train-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  &::before {
    content: "";
    background-image: url("@/assets/img/train/kj.jpg");
    opacity: 0.3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .train-header-container {
    height: 72px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    .header-nav {
      width: 100%;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $default-bg;
      padding: 5px 15px;
      // position: fixed;
      // top: 0;
      // left: 0;
      // z-index: 9;

      .header-logo {
        width: 146px;
        height: 40px;
        margin-right: 10px;
      }

      /deep/.van-icon {
        font-size: 32px;
      }

      /deep/.van-tab__text {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .page-container {
    width: 100%;
    height: 100%;
    // overflow-y: scroll;
  }

  /deep/ .page-container .page-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    // width: fit-content;
    width: 100%;
    align-items: center;
  }

  .swiper-container-par {
    width: 100%;
    height: 100%;
    padding-top: 72px;
    .swiper-slide {
      width: 100%;
      height: 100%;
      color: #ffffff;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }

  .swiper-pagination {
    width: 50%;
    height: 100%;
    transform: translate3d(0, -50%, 0);
    position: fixed;
    top: 50%;
    left: 50%;
    right: 0;
    max-height: 100%;
    overflow-y: auto;
    transition: transform 0.3s;
  }

  /deep/.van-overlay {
    background-color: rgba($color: #000000, $alpha: 0.4);
  }

  .swiper-popup-pagination {
    width: 50%;
    height: 100%;
    padding: 0 20px;
    box-sizing: content-box;
    background-color: #212121;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .pagination-list {
      margin-top: 72px;
      flex: 1;
      &.pagination-bottom {
        margin-top: 0;
        padding: 20px 0;
        flex: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
      }
      /deep/.swiper-pagination-custom-item {
        display: block;
        width: 100%;
        line-height: 32px;
        margin: 18px 0;
        font-size: 18px;
        color: #9abfcc;
        &.swiper-pagination-custom-item-active {
          color: #ffffff;
          font-weight: bold;
        }
      }
    }
  }

  .video-title {
    width: 90%;
    color: #30cada;
    font-weight: bold;
    font-size: 24px;
    background-color: transparent;
    line-height: 36px;
    text-align: left;
    padding-left: 10px;
  }

  /deep/.van-popup__close-icon {
    top: 6px;
  }

  // 视频相关css
  /deep/.video-js .vjs-big-play-button {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .vjs-icon-placeholder:before {
      font-size: 60px;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  /deep/.video-js .vjs-poster {
    background-size: 100% 100%;
  }

  /deep/.video-js .vjs-volume-panel {
    display: none;
  }
  /deep/.video-js.vjs-fluid {
    height: 100%;
    .vjs-tech {
      object-fit: cover;
    }
  }
}
</style>