import Vue from 'vue'
import App from './App.vue'
import Cookies from "js-cookie";

Vue.config.productionTip = false

// 导入路由
import router from './router'

// 导入vuex
import Vuex from 'vuex'
import store from './store'
Vue.use(Vuex)

// 引入vantUI（按需引入组件）
import '@/plugins/vant'

import 'lib-flexible'

import '@/assets/iconfont/iconfont.css'

import moment from 'moment'
//  全局过滤器 时间戳 
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!dataStr) {
    return;
  }
  return moment(dataStr).format(pattern);
})

// swiper轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

const TcPlayer = window.TCPlayer;
console.log('TcPlayer:',TcPlayer)
Vue.prototype.TcPlayer = TcPlayer;

// 如果是PC端设备，跳转到PC端界面
import { _isMobile } from './utils'
const isMobile = _isMobile();
if (process.env.NODE_ENV === 'production') {
  if (!isMobile) {
    // 跳转至 pc 端路由
    window.location.href = "http://www.glsxvfx.com";
  }
}

Vue.prototype.$cookie = Cookies  //挂载全局


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
