<template>
  <div class="page-container case-container">
    <div class="case-content" ref="contentBox">
      <div class="case-title" ref="caseTitle">Unity特效课程案例</div>
      <!-- :style="{height: 'calc(100% - 40px - ' + titleHeight + ')'}" -->
      <div class="case-list-box">
        <!-- 分页器 -->
        <div class="case-prev" @click="eventChangePage('prev')">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/page.png"
          />
        </div>
        <div class="case-next" @click="eventChangePage('next')">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/page.png"
          />
        </div>
        <div
          class="case-list-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="eventClickItem(item.videoUrl, item.title)"
        >
          <div class="item-left">
            <p class="item-title">{{ item.title }}</p>
            <div class="item-content-box">
              <div
                class="content-line"
                v-for="(citem, cindex) in item.lineList"
                :key="cindex"
              >
                <van-icon class="content-icon" :name="citem.icon" />
                <p v-html="citem.text"></p>
              </div>
            </div>
          </div>
          <div class="item-right">
            <van-image width="100%" lazy-load :src="item.src" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 2,
      loading: false,
      finished: true,
      titleHeight: "0px",
      showContent: true,
      queryData: {
        pageSize: 3,
        pageNum: 1,
      },
      dataList: [],
      totalList: [
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_1.jpg",
          title: "基础篇-弹道类",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/1.mp4",
          lineList: [
            {
              icon: "setting",
              text: "粒子基础属性学习<br />流动材质制作",
            },
            {
              icon: "success",
              text: "光的色彩和分布<br />初识亮色与暗色",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_2.jpg",
          title: "基础篇-爆点受击",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/2.mp4",
          lineList: [
            {
              icon: "setting",
              text: "粒子基础属性学习<br />遮罩材质制作",
            },
            {
              icon: "success",
              text: "如何体现节奏<br />不同的打击感",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_3.jpg",
          title: "基础篇-护盾Buff",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/3.mp4",
          lineList: [
            {
              icon: "setting",
              text: "粒子基础属性学习<br />单面双面材质的制作",
            },
            {
              icon: "success",
              text: "科技风的贴图绘制<br />特效与人物动作的配合",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_4.jpg",
          title: "基础篇-护盾Buff",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/4.mp4",
          lineList: [
            {
              icon: "setting",
              text: "特效模型制作技巧<br />顶点位移材质",
            },
            {
              icon: "success",
              text: "模型与贴图的巧妙配合<br />思路拓展训练",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_5.jpg",
          title: "基础篇-刀光",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/5.mp4",
          lineList: [
            {
              icon: "setting",
              text: "基础刀光层次与纹理表现",
            },
            {
              icon: "success",
              text: "运动节奏与颜色节奏",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_6.jpg",
          title: "进阶篇-高级刀光",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/6.mp4",
          lineList: [
            {
              icon: "setting",
              text: "特殊刀光的制作思路",
            },
            {
              icon: "success",
              text: "模型与贴图的制作思路",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_7.jpg",
          title: "进阶篇-范围技能1",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/7.mp4",
          lineList: [
            {
              icon: "setting",
              text: "风格的表现形式<br/>精致度的表现方式",
            },
            {
              icon: "success",
              text: "多段攻击的节奏表达<br/>主体元素与辅助元素的搭配",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_8.jpg",
          title: "进阶篇-水系技能",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/8.mp4",
          lineList: [
            {
              icon: "setting",
              text: "扰动材质制作",
            },
            {
              icon: "success",
              text: "液体元素的难点解析",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_9.jpg",
          title: "进阶篇-刀剑连击技",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/9.mp4",
          lineList: [
            {
              icon: "setting",
              text: "人物模型在特效中的使用方法",
            },
            {
              icon: "success",
              text: "战士与法师技能的区别",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_10.jpg",
          title: "进阶篇-范围技能2",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/10.mp4",
          lineList: [
            {
              icon: "setting",
              text: "软溶解于硬溶解材质制作",
            },
            {
              icon: "success",
              text: "特定元素的制作思路",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_11.jpg",
          title: "进阶篇-范围技能3",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/11.mp4",
          lineList: [
            {
              icon: "setting",
              text: "制作思路综合拓展",
            },
            {
              icon: "success",
              text: "美术风格拓展训练<br/>制作效率训练",
            },
          ],
        },
        {
          src: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_12.jpg",
          title: "进阶篇-范围技能4",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/12.mp4",
          lineList: [
            {
              icon: "setting",
              text: "制作思路综合拓展",
            },
            {
              icon: "success",
              text: "美术风格拓展训练<br/>制作效率训练",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.titleHeight = this.$refs.caseTitle.offsetHeight + "px";
    // console.log(
    //   Math.floor(
    //     (this.$refs.contentBox.offsetHeight -
    //       this.$refs.caseTitle.offsetHeight -
    //       70) /
    //       (140 * (this.$refs.contentBox.offsetWidth / 375))
    //   )
    // );
    this.getInit();
  },
  methods: {
    // 初始化
    getInit() {
      // 比例
      let scaleSize = this.$refs.contentBox.offsetWidth / 375;
      // 根据屏幕高度自动调节页大小,默认为3
      this.queryData.pageSize = Math.floor(
        (this.$refs.contentBox.offsetHeight -
          this.$refs.caseTitle.offsetHeight -
          70) /
          (140 * scaleSize)
      );
      this.getListData();
    },
    // 获取数据
    getListData() {
      let startPage = (this.queryData.pageNum - 1) * this.queryData.pageSize;
      let endPage = startPage + this.queryData.pageSize;
      this.dataList = this.totalList.slice(startPage, endPage);
    },
    eventChangePage(type) {
      if (type === "prev") {
        if (this.queryData.pageNum <= 1) {
          this.$toast("这就是第一页啦");
          return;
        }
        this.queryData.pageNum -= 1;
        this.getListData();
      } else if (type === "next") {
        if (
          this.queryData.pageNum >=
          this.totalList.length / this.queryData.pageSize
        ) {
          this.$toast("已经是最后一页啦");
          return;
        }
        this.queryData.pageNum += 1;
        this.getListData();
      }
    },
    eventClickItem(videoUrl, videoTitle) {
      this.$emit('changeVideo', videoUrl, videoTitle);
    }
  },
};
</script>

<style lang="scss" scoped>
.case-container {
  width: 100%;
  height: 100%;
  .case-content {
    padding-top: 20px;
    height: 100%;
    // overflow: hidden;
    .case-title {
      font-size: 24px;
      letter-spacing: 2px;
      color: #ffffff;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }

    .case-list-box {
      padding: 0 40px;
      margin-top: 10px;
      //overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .case-prev {
        position: absolute;
        left: 5px;
        top: calc(50% - 50px);
        width: 30px;
        height: 100px;
        overflow: hidden;
        > img {
          // width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .case-next {
        position: absolute;
        right: 5px;
        top: calc(50% - 50px);
        width: 30px;
        height: 100px;
        overflow: hidden;
        > img {
          // width: 100%;
          height: 100%;
          object-fit: cover;
          transform: rotate(180deg);
          float: right;
        }
      }
      .case-list-item {
        margin-top: 10px;
        width: 100%;
        height: 130px;
        display: flex;
        .item-left {
          background-image: linear-gradient(
            to right,
            rgba(79, 17, 42, 0.7),
            rgba(24, 49, 91, 0.7)
          );
          padding: 10px 15px;
          width: 50%;
          height: 100%;
          text-align: left;
          .item-title {
            font-size: clamp(12px, 14px, 16px);
            color: #ffffff;
            font-weight: bold;
            word-break: break-all;
            white-space: normal;
          }
          .item-content-box {
            padding-top: 5px;
            margin-top: 5px;
            color: #8eb1bd;
            .content-line {
              display: flex;
              align-items: center;
              &:not(:first-child) {
                margin-top: 5px;
              }
              .content-icon {
                margin-right: 5px;
                font-size: 14px;
              }
              > p {
                font-size: 12px;
                zoom: 0.9;
              }
            }
          }
        }
        .item-right {
          width: 50%;
          height: 100%;
          /deep/.van-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            .van-image__img {
              width: calc(100% + 10px);
              height: auto;
              position: absolute;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .case-list-bottom {
        margin-top: 20px;
        /deep/.van-pagination__item--disabled {
        }
        /deep/.van-pagination__item {
          color: #ffffff;
          background-color: rgba(154, 191, 204, 0.2);
        }
      }
    }
  }

  .video-title {
    color: #30cada;
    font-weight: bold;
    font-size: 24px;
    width: 90vw;
    background-color: transparent;
    line-height: 36px;
  }

}
</style>