<template>
  <div class="page-container advantage-container">
    <div class="advantage-content">
      <p class="advantage-title">课程优势</p>
      <van-grid :border="false" :column-num="2" :gutter="30" :center="false">
        <van-grid-item>
          <p class="item-title">专业</p>
          <p class="item-content">课程来自怪力视效技术专家以及公司实战项目研发</p>
        </van-grid-item>
        <van-grid-item>
          <p class="item-title">时效</p>
          <p class="item-content">来自游戏行业最新的技术和流程开发教程</p>
        </van-grid-item>
        <van-grid-item>
          <p class="item-title">应用</p>
          <p class="item-content">所学内容适应不断发展和变化的游戏行业</p>
        </van-grid-item>
        <van-grid-item>
          <p class="item-title">就业</p>
          <p class="item-content">面试指导与应聘测试题，指导职业规划、推荐各位</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  }
};
</script>

<style lang="scss" scoped>
.advantage-container {
  .advantage-content {
    padding-top: 30px;
    .advantage-title {
      font-size: 36px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }

    /deep/.van-grid{
      margin-top: 10vh;
      text-align: left;
    }
    /deep/.van-grid-item__content{
      background: transparent;
    }
    .item-title{
      font-size: 24px;
      width: 100%;

    }
    .item-content{
      font-size: 14px;
      margin-top: 16px;
      color: #9abfcc;
    }
  }
}
</style>