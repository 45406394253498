<template>
  <div class="courses-detail-container">
    <div class="courses-video-box">
      <video
        id="player-container-id"
        preload="auto"
        playsinline
        webkit-playsinline
      ></video>
    </div>
    <div class="courses-video-title text-over-ellipsis">
      {{ detailInfo.seriesName }}
    </div>
    <div class="courses-video-release">
      <van-image
        width="20px"
        height="14px"
        lazy-load
        src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/favicon.png"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <p>怪教授</p>
    </div>
    <div class="courses-video-info">
      <van-tabs
        v-model="activeName"
        background="transparent"
        color="#35edff"
        title-inactive-color="#FFFFFF"
        title-active-color="#35edff"
      >
        <van-tab title="课程简介" name="introduction">
          <p class="info-box">{{ detailInfo.introduction }}</p>
        </van-tab>
        <van-tab title="目录" name="directory">
          <p class="info-box">
            <ul class="video-directory-list">
              <li class="video-item" :class="cursorActiveIndex === index && 'video-item-active'" 
                @click="changeIndex(index, item.isFree)"  
                v-for="(item, index) in cursorList" :key="item.id">
                <van-icon name="play-circle" class="video-icon" />
                {{ index + 1 >= 10 ? index + 1 : "0" + (index + 1) }}. {{item.title}}
                <!-- <van-tag type="danger">试看</van-tag> -->
              </li>
            </ul>
          </p>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getSeriesDetailById, setIntegralByTime } from "@/api/courses.js";
export default {
  data() {
    return {
      player: undefined,
      cursorList: [],
      cursorActiveIndex: undefined,
      detailInfo: {},
      activeName: "introduction",
      test: false,
      fileId: undefined,
      // 是否首次观看
      isFirstLook: true,
      // 是否看过了1/3
      isOneInThree: false,
      // 是否看过了2/3
      isTwoInThree: false,
      // 是否看完过
      isFinsh: false,
    };
  },
  // computed: {
  //   fileId() {
  //     return (
  //       this.cursorList[this.cursorActiveIndex] &&
  //       this.cursorList[this.cursorActiveIndex].videoSourceId
  //     );
  //   },
  // },
  watch: {
    cursorActiveIndex: {
      handler(v) {
        this.fileId = this.cursorList[this.cursorActiveIndex].videoSourceId;
        this.changeVideoFile();
      },
    },
    // fileId: {
    //   handler(v) {
    //     this.changeVideoFile();
    //   },
    // },
  },
  mounted() {
    let cid = this.$route.query.id;
    cid && this.getDetail(cid, this.cursorActiveIndex);
    this.init();
  },
  methods: {
    // 获取详情信息
    async getDetail(id, cursorActiveIndex) {
      let { code, data } = await getSeriesDetailById({ parentId: id });
      if (code === 200) {
        this.$set(this.detailInfo, "seriesName", data.seriesName);
        this.$set(this.detailInfo, "price", data.price / 100);
        this.$set(this.detailInfo, "pid", id);
        this.$set(this.detailInfo, "introduction", data.introduction);
        this.$set(this.detailInfo, "charge", data.charge);
        this.$set(this.detailInfo, "isBuy", data.isBuy);
        this.cursorList = data.List;
        // this.isBuy = data.isBuy;
        this.cursorActiveIndex = cursorActiveIndex || 0;
        this.changeIndex(
          this.cursorActiveIndex,
          this.cursorList[this.cursorActiveIndex].isFree
        );
      }
    },
    // 初始化播放器
    async init() {
      this.player = new this.TcPlayer("player-container-id", {
        fileID: this.fileId, // 请传入需要播放的视频fileID 必须
        appID: "1314030635", // 请传入点播账号的子应用appID 必须
        psign: "", // 请传入播放器签名psign
        autoplay: true, // 是否自动播放
        controls: "system",
      });

      let timeTest = undefined;
      this.playCountTime = 0;
      this.player.on("play", (event) => {
        timeTest = setInterval(() => {
          this.playCountTime += 0.25;
          console.log("this.playCountTime:", this.playCountTime);
          this.time();
        }, 250);
      });

      this.player.on("pause", (event) => {
        this.playCountTime += 0.25;
        clearInterval(timeTest);
        timeTest = undefined;
      });
    },
    time() {
      let countTime = this.player.duration();
      console.log(countTime);
      let one = Math.floor(countTime / 3);
      console.log(one);
      let lookprogress = document
        .getElementsByClassName("vjs-progress-holder")[0]
        .getAttribute("aria-valuenow");
      console.log(lookprogress);
      let type = undefined;
      if (this.isFirstLook) {
        this.isFirstLook = false;
        type = 1;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            Toast.success(res.msg);
          }
        });
      }
      if (this.playCountTime >= one && lookprogress > 33 && !this.isOneInThree) {
        console.log("看到1/3了");
        this.isOneInThree = true;
        type = 2;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            Toast.success(res.msg);
          }
        });
      }
      if (this.playCountTime >= one * 2 && lookprogress > 66 && !this.isTwoInThree) {
        console.log("看到2/3了");
        this.isTwoInThree = true;
        type = 3;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            Toast.success(res.msg);
          }
        });
      }
      if (this.playCountTime >= one * 3 && lookprogress > 95 && !this.isFinsh) {
        console.log("看完了");
        this.isFinsh = true;
        type = 4;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            Toast.success(res.msg);
          }
        });
      }
    },
    // 切换视频
    changeVideoFile() {
      this.playCountTime = 0;
      this.isFirstLook = true;
      this.isOneInThree = false;
      this.isTwoInThree = false;
      this.isFinsh = false;
      this.player.loadVideoByID({ fileID: this.fileId, appID: "1314030635" });
    },
    // 修改下标
    changeIndex(index, isFree) {
      // if (!this.$cookie.get("userInfo") && !isFree) {
      //   this.$store.commit("setShowLoginDialog", true);
      //   return false;
      // }
      // if (!isFree && !this.isBuy) {
      //   this.showToast = true;
      //   return;
      // }
      this.cursorActiveIndex = index;
      document.documentElement.scrollTop = 0;
    },
    handleClick() {},
  },
  destroyed() {
    // 页面销毁，同时也销毁 TcPlayer
    this.player.dispose();
  },
};
</script>

<style lang="scss" scoped>
.courses-detail-container {
  width: 100%;
  .courses-video-box {
    width: 100%;
    height: 200px;
    #player-container-id {
      width: 100%;
      height: 100%;
    }

    /deep/.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
      display: none;
    }

    /deep/.tcp-skin:not(.vjs-live) .vjs-current-time,
    /deep/.tcp-skin:not(.vjs-live) .vjs-time-divider,
    /deep/.tcp-skin:not(.vjs-live) .vjs-duration {
      display: none;
    }

    /deep/.video-js .vjs-big-play-button {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // font-size: 16px;
      // border: none;
      // background-color: transparent;
    }

    /deep/.vjs-playback-rate {
      display: none;
    }

    /deep/.vjs-progress-control {
      top: 0;
    }

    /deep/.video-js .vjs-big-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border: 0;
    }
  }
  .courses-video-title {
    color: #ffffff;
    font-size: 18px;
    line-height: 40px;
    text-align: left;
    padding: 0 15px;
    width: 100%;
  }
  .courses-video-release {
    display: flex;
    align-items: center;
    padding: 0 15px 10px;
    > p {
      color: #ffffff;
      margin-left: 5px;
    }
  }
  .courses-video-info {
    padding: 0;
    .info-box {
      padding: 10px;
      color: #bcbcbc;
      min-height: 300px;
      text-align: left;

      .video-directory-list {
        .video-item {
          padding: 10px 20px;
          background-color: #1f2939;
          font-size: 14px;

          &:not(:first-child) {
            margin-top: 10px;
          }

          .video-icon {
            margin-right: 10px;
          }
          &.video-item-active {
            color: #35edff;
          }
        }
      }
    }
  }
}
</style>