import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '@/store/index'
// import { _isMobile } from '@/utils/index'
// const isMobile = _isMobile();
// if (process.env.NODE_ENV !== 'production') {
//   if (!isMobile) {
//     // 跳转至 pc 端路由
//     window.location.href = "http://www.glsxvfx.com";
//   }
// }

// 引入组件

// 论坛
import Forum from '@/views/forum/index'

// 论坛详情
import ForumDetail from '@/views/forum/detail'

// 教育培训
import Train from '@/views/train/index'

// 在线课程
import Courses from '@/views/courses/index'
import CoursesDetail from '@/views/courses/detail'

// 登录
import Login from '@/views/login/index'



Vue.use(VueRouter);


const routes = [
  { path: '/', name: 'forum', component: Forum },
  { path: '/login', name: 'login', component: Login },
  { path: '/forum', name: 'forum', component: Forum},
  { path: '/forum/detail', name: 'forumDetail', component: ForumDetail, meta: { isHideFooter: true } },
  { path: '/train', name: 'train', component: Train, meta: { isHideFooter: true, isHideHeader: true } },
  { path: '/courses', name: 'train', component: Courses },
  { path: '/courses/detail', name: 'coursesDetail', component: CoursesDetail }
]

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push

// 修改原型对象中的push方法，捕捉错误
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(function (to, from, next) {
  let meta = to.matched[0].meta;
  // to.matched.some(record => !record.meta.isShowFooter
  store.commit('setIsShowFooter', !meta.isHideFooter);
  store.commit('setIsShowHeader', !meta.isHideHeader);
  next();
})

export default router