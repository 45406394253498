<template>
  <div class="page-container question-container">
    <div class="question-content">
      <div class="question-title">常见问题</div>
      <div class="question-box">
        <div class="question-item">
          <p class="item-title">学多久？学费多少？</p>
          <div class="item-content">
            <p>学期6个月</p>
            <p>原价：24800/人/学期</p>
            <p>早鸟价：23800/人/学期</p>
          </div>
        </div>
        <div class="question-item">
          <p class="item-title">学多久？学费多少？</p>
          <div class="item-content">
            入学面试：专业面试测试评定是否达到入学标准<br />
            免费试学体验课：行不行试了就知道<br />
            专业团队：专业讲师团队，十年特效从业经验
          </div>
        </div>
        <div class="question-item">
          <p class="item-title">学成后，好找工作吗？</p>
          <div class="item-content">
            行业缺口大，项目多，急需人才<br />拒绝华丽胡哨项目，实用技巧训练无缝对接<br />
            总部、分部+多家合作企业等广阔推荐平台
          </div>
        </div>
        <div class="question-item">
          <p class="item-title">学成后，待遇如何？</p>
          <div class="item-content">
            能者多得，根据个人实力谈待遇没有上限<br />
            怪力初级4K-6K，怪力中级7K-9K，怪力高级10K-20K+<br />
            怪力双休、绩效奖金、年终奖、团建、带薪年假、
            网易、腾讯、字节等大厂内推、节假日福利、旅游、
            下午茶、升职加薪等诸多福利
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.question-container {
  .question-content {
    padding-top: 20px;
    .question-title {
      font-size: 24px;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }
    .question-box {
      margin: 20px 5vw;
      width: 90vw;
      .question-item {
        &:not(:first-child) {
          margin-top: 10px;
        }
        .item-title {
          font-size: 12px;
          margin-bottom: 5px;
          text-align: left;
        }
        .item-content {
          width: 100%;
          border: 1px solid #647a84;
          background-color: rgba(40, 45, 60, 0.9);
          padding: 10px;
          font-size: 12px;
          zoom: 0.9;
          text-align: left;
          color: #809faa;
          > p {
            line-height: 24px;
            &:not(:first-child) {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>