<template>
  <div class="page-container case-container">
    <div class="case-content">
      <!-- <div class="case-content-top">
        <div class="case-content-title">
          <p>Made</p>
          <p>with</p>
          <p>怪力</p>
        </div>
      </div> -->
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in imgList" :key="index">
          <div class="swiper-img-item">
            <img :src="item" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination-case" slot="pagination">
          <div class="swiper-custom-text"><p>Made<br/>with<br/>怪力</p></div>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        loop: true,
        touchStartPreventDefault: false,
        pagination: {
          el: ".swiper-pagination-case",
          type: "custom",
          // renderCustom: function (swiper, current, total) {
          //   return '<div class="swiper-custom-text"><p>Made<br/>with<br/>怪力</p></div>';
          // },
        },
      },
      imgList: [
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case-yys.png",
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case_hgwz.jpg",
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case-dwrg.jpg",
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case-wzry.png",
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case-paj.png",
        "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/mobile/train/case/case-hpjy.jpg",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.case-content {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  .case-content-top {
    height: 20px;
    position: relative;
    .case-content-title {
      width: 65%;
      text-align: left;
      position: absolute;
      left: calc(22.5% - 15px);
      top: -50px;
      z-index: 10;
      > p {
        font-size: 48px;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}

.swiper-container {
  height: 100%;
}
/deep/.swiper-wrapper {
  margin-left: calc(22.5% - 15px);
  height: 100%;
}
.swiper-slide {
  width: 65%;
  display: flex;
  align-items: center;
  .swiper-img-item {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 133%;
    z-index: 0;
    /deep/.van-image {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    > img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
}

/deep/.swiper-pagination-case.swiper-pagination-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 48px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  .swiper-custom-text {
    text-align: left;
    padding-left: calc(22.5% - 15px);
    position: relative;
    // height: 100%;
    // width: 100%;
    > p {
      position: absolute;
      //left: calc(22.5% - 15px);
      top: 70px;
    }
  }
}
</style> 