<template>
  <div class="page-container home-container">
    <div class="home-content">
      <div class="home-content-title">
        <p>怪力屋集训营</p>
        <p>游戏美术精品课程</p>
      </div>
      <div class="home-content-describe">
        <div class="home-describe-icon">精品</div>
        <p>怪力屋 • 游戏美术</p>
      </div>
      <div class="home-content-type">
        <div class="content-type-item">
          <div class="item-top" @click="eventToPage(4, {curriculumActive: 1})">
            <div class="item-icon">
              <img
                src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/Unity.png"
              />
            </div>
            <div class="item-name">Unity引擎特效课程</div>
          </div>
          <div class="item-flag">
            <p>基础课堂</p>
            <p>进阶课堂</p>
            <p>6个月</p>
          </div>
        </div>
        <div class="content-type-item">
          <div class="item-top" @click="eventToPage(4, {curriculumActive: 2})">
            <div class="item-icon">
              <img
                src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/3dsmax.png"
              />
            </div>
            <div class="item-name">3dsMax游戏动作课程</div>
          </div>
          <div class="item-flag">
            <p>基础课堂</p>
            <p>进阶课堂</p>
            <p>6个月</p>
          </div>
        </div>
      </div>
      <div class="home-content-footer" @click="eventToPage(10)">
        <p>留言咨询</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    eventToPage(page, attributesObj) {
      this.$emit("eventToPage", page, attributesObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  color: #ffffff;
  .home-content {
    padding-top: 20px;
    text-align: center;
    .home-content-title {
      p:nth-child(1) {
        font-size: 48px;
        letter-spacing: 5px;
        font-weight: bold;
      }
      p:nth-last-child(1) {
        margin-top: 40px;
        font-size: 16px;
        letter-spacing: 2px;
      }
    }
    .home-content-describe {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      font-size: 14px;
      .home-describe-icon {
        padding: 0 15px;
        color: #47ffff;
        border: 1px solid #47ffff;
        line-height: 20px;
        border-radius: 17px;
        text-align: center;
        min-width: 70px;
        letter-spacing: 2px;
      }
      > p {
        margin-left: 20px;
      }
    }
    .home-content-type {
      display: flex;
      width: 100%;
      margin-top: 50px;
      color: #9abfcc;
      .content-type-item {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-top {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item-icon {
          width: 80px;
          height: 80px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .item-name {
          margin-top: 10px;
          font-size: 14px;
        }
        .item-flag {
          margin-top: 10px;
          font-size: 12px;
          > p {
            margin-top: 20px;
          }
        }
      }
    }
    .home-content-footer {
      margin-top: 20px;
      > p {
        font-size: 14px;
        color: #9abfcc;
      }
    }
  }
}
</style>