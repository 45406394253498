<template>
  <div class="page-container work-container">
    <div class="work-content" ref="contentBox">
      <div class="work-title" ref="workTitle">Unity特效学员作品</div>
      <div class="work-list-box">
        <!-- 分页器 -->
        <div class="work-prev" @click="eventChangePage('prev')">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/page.png"
          />
        </div>
        <div class="work-next" @click="eventChangePage('next')">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/page.png"
          />
        </div>
        <div class="work-list-item" v-for="(item, index) in dataList" :key="index">
          <p class="item-title">{{item.title}}</p>
          <ul class="item-work-list">
            <li v-for="(citem, cindex) in item.workList" :key="cindex" @click="eventClickItem(citem.videoUrl, citem.title)">
              <van-image
                width="120px"
                height="96px"
                lazy-load
                :src="citem.cover"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryData: {
        pageSize: 3,
        pageNum: 1,
      },
      dataList: [],
      totalList: [
        {
          title: "五期",
          workList: [
            {
              title: '五期学员作品1',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-1.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-1.mp4",
            },
            {
              title: '五期学员作品2',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-2.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-2.mp4",
            },
            {
              title: '五期学员作品3',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-3.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-3.mp4",
            },
          ],
        },
        {
          title: "四期",
          workList: [
            {
              title: '四期学员作品1',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-1.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-1.mp4",
            },
            {
              title: '四期学员作品2',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-2.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-2.mp4",
            },
            {
              title: '四期学员作品3',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-3.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-3.mp4",
            },
            {
              title: '四期学员作品4',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-4.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-4.mp4",
            },
            {
              title: '四期学员作品5',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-5.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-5.mp4",
            },
            {
              title: '四期学员作品6',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-6.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-6.mp4",
            },
            {
              title: '四期学员作品7',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-7.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-7.mp4",
            },
          ],
        },
        {
          title: "二期",
          workList: [
            {
              title: '二期学员作品1',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-1.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-1.mp4",
            },
            {
              title: '二期学员作品2',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-2.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-2.mp4",
            },
            {
              title: '二期学员作品3',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-3.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-3.mp4",
            },
          ],
        },
        {
          title: "一期",
          workList: [
            {
              title: '一期学员作品1',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-1.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-1.mp4",
            },
            {
              title: '一期学员作品2',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-2.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-2.mp4",
            },
            {
              title: '一期学员作品3',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-3.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-3.mp4",
            },
            {
              title: '一期学员作品4',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-4.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-4.mp4",
            },
            {
              title: '一期学员作品5',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-5.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-5.mp4",
            },
            {
              title: '一期学员作品6',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-6.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-6.mp4",
            },
            {
              title: '一期学员作品7',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-7.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-7.mp4",
            },
            {
              title: '一期学员作品8',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-8.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-8.mp4",
            },
            {
              title: '一期学员作品9',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-9.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-9.mp4",
            },
            {
              title: '一期学员作品10',
              cover:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-10.jpg",
              videoUrl:
                "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-10.mp4",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getInit();
  },
  methods: {
    // 初始化
    getInit() {
      // 比例
      let scaleSize = this.$refs.contentBox.offsetWidth / 375;
      // 根据屏幕高度自动调节页大小,默认为3
      this.queryData.pageSize = Math.floor(
        (this.$refs.contentBox.offsetHeight -
          this.$refs.workTitle.offsetHeight -
          70) /
          (133 * scaleSize)
      );
      this.getListData();
    },
    // 获取数据
    getListData() {
      let startPage = (this.queryData.pageNum - 1) * this.queryData.pageSize;
      let endPage = startPage + this.queryData.pageSize;
      this.dataList = this.totalList.slice(startPage, endPage);
    },
    eventChangePage(type) {
      if (type === "prev") {
        if (this.queryData.pageNum <= 1) {
          this.$toast("这就是第一页啦");
          return;
        }
        this.queryData.pageNum -= 1;
        this.getListData();
      } else if (type === "next") {
        if (
          this.queryData.pageNum >=
          this.totalList.length / this.queryData.pageSize
        ) {
          this.$toast("已经是最后一页啦");
          return;
        }
        this.queryData.pageNum += 1;
        this.getListData();
      }
    },
    eventClickItem(videoUrl, videoTitle) {
      this.$emit("changeVideo", videoUrl, videoTitle);
    },
  },
};
</script>

<style lang="scss" scoped>
.work-container {
  .work-content {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    .work-title {
      font-size: 24px;
      letter-spacing: 2px;
      color: #ffffff;
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 30px;
      &::after {
        content: "";
        width: 100px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -10px;
        left: 30px;
      }
    }
    .work-list-box {
      padding: 0 40px;
      margin-top: 10px;
      //overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .work-prev {
        position: absolute;
        left: 5px;
        top: calc(50% - 50px);
        width: 30px;
        height: 100px;
        overflow: hidden;
        > img {
          // width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .work-next {
        position: absolute;
        right: 5px;
        top: calc(50% - 50px);
        width: 30px;
        height: 100px;
        overflow: hidden;
        > img {
          // width: 100%;
          height: 100%;
          object-fit: cover;
          transform: rotate(180deg);
          float: right;
        }
      }
      .work-list-item {
        width: 100%;
        overflow: hidden;
        margin-top: 10px;
        .item-title {
          width: 100%;
          text-align: left;
          font-size: 14px;
          margin-bottom: 5px;
        }
        ul.item-work-list {
          display: flex;
          overflow-x: auto;
          > li {
            margin-right: 10px;
            > img {
            }
          }
        }
      }
    }
  }
}
</style>